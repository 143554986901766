/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
    Col,
    Row,
    Form,
    Label,
    Card,
    CardHeader,
    CardBody,
    NavItem,
} from "reactstrap";
import { Link } from "react-router-dom";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import { DailyReport } from "../CustomComponent/BackendDataConfig";
import { FormatDateMMDDYYY, CustomTextBlue, useStyles } from "../CustomComponent/reuseableFn";
import Button from "@material-ui/core/Button";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

export function NewReport() {
    const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
    const [ReportDailyData, setReportDailyData] = React.useState([]);
    const [ReportDate, setReportDate] = React.useState(new Date());
    //grocery
    const [Tax, setTax] = React.useState(0);
    const [NonTax, setNonTax] = React.useState(0);
    const [OtherTax, setOtherTax] = React.useState(0);
    const [OtherNonTax, setOtherNonTax] = React.useState(0);
    const [Beer, setBeer] = React.useState(0);
    const [Cigratte, setCigratte] = React.useState(0);
    const [TotalTaxSale, setTotalTaxSale] = React.useState(0);
    const [TotalTaxCollected, setTotalTaxCollected] = React.useState(0);
    const [TotalNonTaxSale, setTotalNonTaxSale] = React.useState(0);
    //cash, card, expense
    const [CreditDebitCard, setCreditDebitCard] = React.useState(0);
    const [Cash, setCash] = React.useState(0);
    const [CardCash, setCardCash] = React.useState(0);
    const [CheckCashing, setCheckCashing] = React.useState(0);
    const [MoneyOrder, setMoneyOrder] = React.useState(0);
    const [WesternUnion, setWesternUnion] = React.useState(0);
    // summary report
    const [TotalGrocery, setTotalGrocery] = React.useState(0);
    const [LotteryLottoSale, setLotteryLottoSale] = React.useState(0);
    const [LotteryLottoCash, setLotteryLottoCash] = React.useState(0);
    const [TotalSale, setTotalSale] = React.useState(0);
    const [ExpensePurchase, setExpensePurchase] = React.useState(0);

    const [OverShortShift, setOverShortShift] = React.useState(0);
    //food
    const [Deli, setDeli] = React.useState(0);
    const [OtherFood, setOtherFood] = React.useState(0);
    //year, month, date selected to filter the data
    let date = ReportDate.getDate();
    let month = ReportDate.getMonth() + 1;
    let year = ReportDate.getFullYear();
    const classes = useStyles();
    var SelectedMonth = localStorage.getItem('Month');
    var SelectedYear = localStorage.getItem('Year');
    // to filter the data before fetch
    var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

    //initialize to get data of all customers, order history
    useEffect(() => {
        var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
        const dbRefOrder = firebase.database().ref(DailyReport + '/' + username1 + "/" + SelectedYear + "/" + SelectedMonth);
        dbRefOrder.get().then((snapshot) => {
            let users = [];
            if (snapshot.exists()) {
                //console.log("data available");
                snapshot.forEach((snap) => {
                    users.push(snap.val());
                });
                users.map(a => {
                    if (a.Date === FormatDateMMDDYYY(ReportDate)) {
                        setReportDailyData(a)
                        setTax(a.Tax)
                        setNonTax(a.NonTax)
                        setOtherTax(a.OtherTax)
                        setOtherNonTax(a.OtherNonTax)
                        setBeer(a.Beer)
                        setCigratte(a.Cigratte)
                        setDeli(a.Deli)
                        setOtherFood(a.OtherFood)
                        setTotalTaxSale(a.TotalTaxSale)
                        setTotalTaxCollected(a.TotalTaxCollected)
                        setTotalNonTaxSale(a.TotalNonTaxSale)
                        setMoneyOrder(a.MoneyOrder)
                        setWesternUnion(a.WesternUnion)
                        setCheckCashing(a.CheckCashing)
                        setCreditDebitCard(a.CreditDebitCard)
                        setCash(a.Cash)
                        setCardCash(a.CardCash)
                        setTotalGrocery(a.TotalGrocery)
                        setLotteryLottoSale(a.LotteryLottoSale)
                        setOverShortShift(a.OverShortShift)
                    }
                    return null
                })
                //console.log(users)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [SelectedYear, SelectedMonth, ReportDate]); // placing this will render on state change
    //console.log(ReportDailyData)
    //console.log(FormatDateMMDDYYY(ReportDate))
    //send data to database and create report for customers
    const handleOrderForm = (event) => {
        event.preventDefault();
        firebase.database().ref(DailyReport + "/" + username + "/" + year + "/" + month + "/" + date).update({
            Date: FormatDateMMDDYYY(ReportDate),
            Tax: Tax,
            NonTax: NonTax,
            OtherTax: OtherTax,
            OtherNonTax: OtherNonTax,
            Beer: Beer,
            Cigratte: Cigratte,
            Deli: Deli,
            OtherFood: OtherFood,
            TotalTaxSale: TotalTaxSale,
            TotalTaxCollected: TotalTaxCollected,
            TotalNonTaxSale: TotalNonTaxSale,
            CreditDebitCard: CreditDebitCard,
            CheckCashing: CheckCashing,
            MoneyOrder: MoneyOrder,
            WesternUnion: WesternUnion,
            Cash: Cash,
            CardCash: CardCash,
            TotalGrocery: TotalGrocery,
            GasTotal: Number(ReportDailyData.GasRegularSale) + Number(ReportDailyData.GasPremiumSale) + Number(ReportDailyData.GasDieselSale),
            LotteryLottoSale: LotteryLottoSale,
            LotteryLottoCash: LotteryLottoCash,
            TotalSale: TotalSale,
            LotteryDiff: ReportDailyData.LotterySale - ReportDailyData.LotteryCash,
            LottoDiff: ReportDailyData.LottoSale - ReportDailyData.LottoCash,
            OverShortShift: OverShortShift,
            ExpensePurchase: ExpensePurchase
        });
        setShowUpdateSuccessMessage(true)
        setTimeout(function () {
            setShowUpdateSuccessMessage(false)
        }, 5000);//wait 5 seconds
    };

    return (
        <>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Select Date for New Report</h3>
                        </div>
                        {
                            ShowUpdateSuccessMessage === true ?
                                <>
                                    <Alert severity="success">
                                        Report Submitted Successfully for {month + "/" + date + "/" + year}
                                    </Alert>
                                </> : null
                        }
                        <div className="col text-right">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    label="Report Date"
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    value={ReportDate}
                                    onChange={(newValue) => {
                                        setReportDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </Row>
                </CardHeader>
            </Card>
            <Form role="form" onSubmit={handleOrderForm}>
                <Row form>
                    <Col md={12}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Summary</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            classes={{
                                                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                            }}
                                            type="submit"
                                        >
                                            Submit Report
                                        </Button>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Tax / Non-tax: </b>
                                            <NumberFormat
                                                value={(Number(TotalTaxSale) + Number(TotalTaxCollected) + Number(TotalNonTaxSale)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setTotalGrocery(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Gas: </b>
                                            <NumberFormat
                                                value={Number(ReportDailyData.GasRegularSale) + Number(ReportDailyData.GasPremiumSale) + Number(ReportDailyData.GasDieselSale)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lottery / Lotto Sale: </b>
                                            <NumberFormat
                                                value={(Number(ReportDailyData.LotterySale) + Number(ReportDailyData.LottoSale)).toFixed(2)}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setLotteryLottoSale(value);
                                                }}
                                            />
                                        </Label>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lottery / Lotto Cash: </b>
                                            <NumberFormat
                                                value={(Number(ReportDailyData.LotteryCash) + Number(ReportDailyData.LottoCash)).toFixed(2)}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setLotteryLottoCash(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Sale: </b>
                                            <NumberFormat
                                                value={(Number(TotalGrocery) +
                                                    Number(ReportDailyData.LotteryDiff) + Number(ReportDailyData.LottoDiff) +
                                                    Number(ReportDailyData.GasRegularSale) + Number(ReportDailyData.GasPremiumSale) + Number(ReportDailyData.GasDieselSale)
                                                    + Number(WesternUnion) + Number(MoneyOrder) + Number(CheckCashing) +
                                                    Number(Deli) + Number(OtherFood)).toFixed(2)
                                                }
                                                onChange={a => setTotalSale(a.target.value)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setTotalSale(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Purchase/Expense: </b>
                                            <NumberFormat
                                                value={Number(ReportDailyData.DailyExpense) + Number(ReportDailyData.DailyPurchase)}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setExpensePurchase(value);
                                                }}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Over / Short: </b>
                                            <NumberFormat
                                                value={(Number(TotalSale) - Number(ExpensePurchase) - Number(CardCash)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setOverShortShift(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <div className="col">
                                    <h3 className="mb-0">Grocery</h3>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Tax"
                                            value={Tax}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setTax(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Non-Tax"
                                            value={NonTax}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setNonTax(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Beer"
                                            value={Beer}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setBeer(value)}
                                        />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Other Tax"
                                            value={OtherTax}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setOtherTax(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Other Non-Tax"
                                            value={OtherNonTax}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setOtherNonTax(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Cigratte"
                                            value={Cigratte}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setCigratte(value)}
                                        />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Deli"
                                            value={Deli}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setDeli(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Other Food"
                                            value={OtherFood}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setOtherFood(value)}
                                        />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Tax Sale: </b>
                                            <NumberFormat
                                                value={(Number(Tax) + Number(Beer) + Number(OtherTax) + Number(Cigratte) + Number(Deli) + Number(OtherTax)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setTotalTaxSale(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Tax Collected: </b>
                                            <NumberFormat
                                                value={((Number(Tax) + Number(Beer) + Number(OtherTax) + Number(Cigratte)) * .0825).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setTotalTaxCollected(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Non - Tax Sale: </b>
                                            <NumberFormat
                                                value={(Number(NonTax) + Number(OtherNonTax)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setTotalNonTaxSale(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <div className="col">
                                    <h3 className="mb-0">Credit Card / Cash</h3>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Credit / Debit Card"
                                            value={CreditDebitCard}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setCreditDebitCard(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Cash"
                                            variant="standard"
                                            currencySymbol="$"
                                            value={Cash}
                                            onChange={(event, value) => setCash(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Expense / Purchase: </b>
                                            <NumberFormat
                                                value={(Number(ReportDailyData.DailyExpense) + Number(ReportDailyData.DailyPurchase)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}

                                            />
                                        </Label>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Check Cashing"
                                            value={CheckCashing}
                                            variant="standard"
                                            currencySymbol="$"
                                            onChange={(event, value) => setCheckCashing(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Money-Order"
                                            variant="standard"
                                            currencySymbol="$"
                                            value={MoneyOrder}
                                            onChange={(event, value) => setMoneyOrder(value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <CurrencyTextField
                                            label="Western Union"
                                            variant="standard"
                                            currencySymbol="$"
                                            value={WesternUnion}
                                            onChange={(event, value) => setWesternUnion(value)}
                                        />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm={9}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Card / Cash: </b>
                                            <NumberFormat
                                                value={(Number(CreditDebitCard) + Number(Cash)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setCardCash(value);
                                                }}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Lottery</h3>
                                    </div>
                                    <div className="col text-right">
                                        <NavItem to="/admin/lottery" tag={Link}>
                                            <i className="fas fa-file-contract" style={{ color: CustomTextBlue }}></i>
                                            <span style={{ color: CustomTextBlue }}>&nbsp;&nbsp;Report</span>
                                        </NavItem>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{}}>
                                            <b>Lottery Sales: </b>
                                            <NumberFormat
                                                value={ReportDailyData.LotterySale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                        <Label style={{}}>
                                            <b>Lotto Sales: </b>
                                            <NumberFormat
                                                value={ReportDailyData.LottoSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{}}>
                                            <b>Lottery Cash: </b>
                                            <NumberFormat
                                                value={ReportDailyData.LotteryCash}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                        <Label style={{}}>
                                            <b>Lotto Cash: </b>
                                            <NumberFormat
                                                value={ReportDailyData.LottoCash}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lottery Diff: </b>
                                            <NumberFormat
                                                value={(Number(ReportDailyData.LotterySale) - Number(ReportDailyData.LotteryCash)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lotto Diff: </b>
                                            <NumberFormat
                                                value={(Number(ReportDailyData.LottoSale) - Number(ReportDailyData.LottoCash)).toFixed(2)}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Gas</h3>
                                    </div>
                                    <div className="col text-right">
                                        <NavItem to="/admin/gas" tag={Link}>
                                            <i className="fas fa-file-contract" style={{ color: CustomTextBlue }}></i>
                                            <span style={{ color: CustomTextBlue }}>&nbsp;&nbsp;Report</span>
                                        </NavItem>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{}}>
                                            <b>Regular Gallons: </b>
                                            <NumberFormat
                                                value={ReportDailyData.GasRegularGallon}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                            />
                                        </Label>
                                        <Label style={{}}>
                                            <b>Regular Price: </b>
                                            <NumberFormat
                                                value={ReportDailyData.GasRegularPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                            />
                                        </Label>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Regular Sale: </b>
                                            <NumberFormat
                                                value={Number(ReportDailyData.GasRegularSale).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{}}>
                                            <b>Premium Gallons: </b>
                                            <NumberFormat
                                                value={ReportDailyData.GasPremiumGallon}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                            />
                                        </Label>
                                        <Label style={{}}>
                                            <b>Premium Price: </b>
                                            <NumberFormat
                                                value={ReportDailyData.GasPremiumPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                            />
                                        </Label>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Premium Sale: </b>
                                            <NumberFormat
                                                value={Number(ReportDailyData.GasPremiumSale).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{}}>
                                            <b>Diesel Gallons: </b>
                                            <NumberFormat
                                                value={ReportDailyData.GasDieselGallon}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                            />
                                        </Label>
                                        <Label style={{}}>
                                            <b>Diesel Price: </b>
                                            <NumberFormat
                                                value={ReportDailyData.GasDieselPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                            />
                                        </Label>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Diesel Sale: </b>
                                            <NumberFormat
                                                value={Number(ReportDailyData.GasDieselSale).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
export default NewReport;
