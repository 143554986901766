/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import firebase from "firebase/app";
import { FormatDateMM } from "views/adminUI/CustomComponent/reuseableFn";
import { FormatDateYYYY } from "views/adminUI/CustomComponent/reuseableFn";
import Login from "./Login";
import { ProfileData } from "views/adminUI/CustomComponent/BackendDataConfig";

export class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      UserFirstName: '',
      UserLastName: '',
      UserPhone: '',
      errorRegisterDetails: null,
      onClickLoginButton: false,
      emailToResetPassword: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitSignUp = this.handleSubmitSignUp.bind(this);
    this.handleLoginButton = this.handleLoginButton.bind(this);

  }
  handleChange = event => {
    //console.log(event.target.id);
    // console.log(event.target.value);
    this.setState({ [event.target.id]: event.target.value });
  };


  handleSubmitSignUp = event => {
    event.preventDefault();

    //console.log(this.state.email);
    // console.log(this.state.password);
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((userCredential) => {
        //console.log(userCredential.isSuccessful)
        //console.log(userCredential.user.uid)
        var username = this.state.email.split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
        firebase.database().ref(ProfileData + '/' + username + "/admin/" + userCredential.user.uid).set({
          UserFirstName: this.state.UserFirstName,
          UserLastName: this.state.UserLastName,
          UserEmail: this.state.email,
          UserPhone: this.state.UserPhone,
          UserAdminUsername: username,
          UID: userCredential.user.uid,
          DashboardPermission: 1,
          DailyReportPermission: 1,
          PurchaseInventoryPermission: 1,
          DailyExpensePermission: 1,
          LotterySalePermission: 1,
          GasSalePermission: 1,
          PermitsPermission: 1,
          WarrentyRecordPermission: 1,
          PayrollPermission: 1,
          OtherPermission: 1,
          ProfilePermission:1,
          AuthorizedUsersAndPermission:1,
          SettingPermission:1,
          SubscriptionPermission:1,
        })
        firebase.database().ref(ProfileData + "/AllUsersPermisssion/" + userCredential.user.uid).set({
          UserFirstName: this.state.UserFirstName,
          UserLastName: this.state.UserLastName,
          UserEmail: this.state.email,
          UserPhone: this.state.UserPhone,
          UserAdminUsername: username,
          UID: userCredential.user.uid,
          DashboardPermission: 1,
          DailyReportPermission: 1,
          PurchaseInventoryPermission: 1,
          DailyExpensePermission: 1,
          LotterySalePermission: 1,
          GasSalePermission: 1,
          PermitsPermission: 1,
          WarrentyRecordPermission: 1,
          PayrollPermission: 1,
          OtherPermission: 1,
          ProfilePermission:1,
          AuthorizedUsersAndPermission:1,
          SettingPermission:1,
          SubscriptionPermission:1,

        })
        localStorage.setItem('email', userCredential.user.email)
        localStorage.setItem('Month', FormatDateMM(new Date()))
        localStorage.setItem('Year', FormatDateYYYY(new Date()))
        //localStorage.setItem("auth", userCredential.operationType);
        //localStorage.setItem("token", userCredential.user.Aa);
        ReactDOM.render(
          <BrowserRouter>
            <Switch>
              <Redirect from="*" to="/admin/dashboard" />
            </Switch>
          </BrowserRouter>,
          document.getElementById("root")
        );
      })
      .catch((error) => {
        console.log("error registering new user")
      });
  }
  handleLoginButton = () => {
    this.setState({
      onClickLoginButton: true
    })
  }
  render() {
    return (
      <>
        {this.state.onClickLoginButton === false ?
          <>
            <br />
            <AuthHeader
              title="Welcome!"
              lead="Retail Accounting Software"
            />
            <br /><br /><br /><br /><br />
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="px-lg-5 py-lg-5">
                      <div className="text-center mb-4">
                        <small color="black"> Sign Up with Credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmitSignUp}>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fa fa-user" aria-hidden="true"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="First Name"
                              type="text"
                              id="UserFirstName"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fa fa-user" aria-hidden="true"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Last Name"
                              type="text"
                              id="UserLastName"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i class="fa fa-mobile" aria-hidden="true"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Phone Number"
                              type="number"
                              id="UserPhone"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              id="email"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              id="password"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        {this.state.errorRegisterDetails !== null ?
                          <div><br></br>
                            <Alert color="danger">
                              {this.state.errorRegisterDetails}
                            </Alert>
                          </div> : null}
                        <div className="text-center">
                          <Button className="my-4" color="info" type="submit">
                            Sign Up
                          </Button>
                        </div>
                      </Form>
                    </CardHeader>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="12">
                      <div align="right">
                        <a className="text-light" href="#Login" onClick={this.handleLoginButton}>
                          <small style={{ color: 'black' }}>Login?</small>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </>
          :
          <>
            <Login />
          </>}
      </>
    );
  }
}
export default Register;
