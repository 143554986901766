import React from "react";
import { Container } from "reactstrap";

export function Settings() {
  return (
    <>
      <br /><br /><br /><br></br>
      <Container className="mt--6" fluid>
        Under Development Coming Soon!
      </Container>
      <br></br>
    </>
  );
}
export default Settings;
