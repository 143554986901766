/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
  Col,
  Row,
  Container,
  Card,
  CardHeader,
} from "reactstrap";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import {
  DailyReport
} from "./CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import { tableIcons, UndefinedReturnZero, useStyles } from "./CustomComponent/reuseableFn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SummaryDailyGasReport, SummaryDailyGroceryReport, SummaryDailyLotteryReport, SummaryDailyReport } from "./CustomComponent/TableColumn";
import TableHeaderColor from "./CustomComponent/Colors";
import Alert from '@mui/material/Alert';
import NewReport from "./SubReport/NewReport";
import ViewReport from "./SubReport/ViewReport";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export function Report() {

  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const [openDialogOrderDetails, setOpenDialogOrderDetails] = React.useState(false);
  const [ReportHistory, setReportHistory] = React.useState([]);
  //MTD report info
  const [MTDTotalSale, setMTDTotalSale] = React.useState(0);
  const [MTDTotalSalesTax, setMTDTotalSalesTax] = React.useState(0);
  //
  const [MTDTax, setMTDTax] = React.useState(0);
  const [MTDNonTax, setMTDNonTax] = React.useState(0);
  const [MTDBeer, setMTDBeer] = React.useState(0);
  const [MTDCigratte, setMTDCigratte] = React.useState(0);
  const [MTDDeli, setMTDDeli] = React.useState(0);
  const [MTDOtherFood, setMTDOtherFood] = React.useState(0);
  //
  const [MTDPurchase, setMTDPurchase] = React.useState(0);
  const [MTDExpense, setMTDExpense] = React.useState(0);
  const [MTDTotalOverShort, setMTDTotalOverShort] = React.useState(0);
  //
  const [MTDMoneyOrder, setMTDMoneyOrder] = React.useState(0);
  const [MTDCheckCashing, setMTDCheckCashing] = React.useState(0);
  const [MTDWesternUnion, setMTDWesternUnion] = React.useState(0);
  //
  const [MTDTotalGasGallon, setMTDTotalGasGallon] = React.useState(0);
  const [MTDTotalGasSale, setMTDTotalGasSale] = React.useState(0);
  const [MTDTotalDieselGallon, setMTDTotalDieselGallon] = React.useState(0);
  const [MTDTotalDieselSale, setMTDTotalDieselSale] = React.useState(0);
  //
  const [MTDLotterySale, setMTDLotterySale] = React.useState(0);
  const [MTDLotteryCash, setMTDLotteryCash] = React.useState(0);
  const [MTDLottoSale, setMTDLottoSale] = React.useState(0);
  const [MTDLottoCash, setMTDLottoCash] = React.useState(0);
  //
  const [MTDTotalCreditDebit, setMTDTotalCreditDebit] = React.useState(0);
  const [MTDTotalCash, setMTDTotalCash] = React.useState(0);
  //
  const [MTDTotalLotteryLotto, setMTDTotalLotteryLotto] = React.useState(0);
  const [MTDTotalGrocery, setMTDTotalGrocery] = React.useState(0);
  const [MTDTotalPurchaseExpense, setMTDTotalPurchaseExpense] = React.useState(0);

  const [selectedRowData, setSelectedRowData] = React.useState({});
  const classes = useStyles();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xl");
  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');

  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const dbRefOrder = firebase.database().ref(DailyReport + '/' + username1 + "/" + SelectedYear + "/" + SelectedMonth);
    dbRefOrder.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push(snap.val());
        });
        setReportHistory(users);

        setMTDTotalSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.TotalSale)), 0))
        setMTDTotalSalesTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.TotalTaxCollected)), 0))
        //
        setMTDTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Tax)), 0))
        setMTDNonTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.NonTax)), 0))
        setMTDBeer(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Beer)), 0))
        setMTDCigratte(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Cigratte)), 0))
        setMTDDeli(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Deli)), 0))
        setMTDOtherFood(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.OtherFood)), 0))
        //
        setMTDPurchase(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.DailyPurchase)), 0))
        setMTDExpense(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.DailyExpense)), 0))
        setMTDTotalOverShort(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.OverShortShift)), 0))
        //
        setMTDMoneyOrder(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.MoneyOrder)), 0))
        setMTDCheckCashing(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.CheckCashing)), 0))
        setMTDWesternUnion(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.WesternUnion)), 0))
        //
        setMTDTotalGasGallon(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasRegularGallon)) + Number(UndefinedReturnZero(b.GasPremiumGallon)), 0))
        setMTDTotalGasSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasRegularSale)) + Number(UndefinedReturnZero(b.GasPremiumSale)), 0))
        setMTDTotalDieselGallon(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasDieselGallon)), 0))
        setMTDTotalDieselSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasDieselSale)), 0))
        //
        setMTDLotterySale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.LotterySale)), 0))
        setMTDLotteryCash(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.LotteryCash)), 0))
        setMTDLottoSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.LottoSale)), 0))
        setMTDLottoCash(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.LottoCash)), 0))
        //
        setMTDTotalCreditDebit(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.CreditDebitCard)), 0))
        setMTDTotalCash(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Cash)), 0))
        setMTDTotalLotteryLotto(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.LotteryLottoSale)), 0))
        setMTDTotalGrocery(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.TotalGrocery)), 0))
        setMTDTotalPurchaseExpense(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.DailyExpense)) + Number(UndefinedReturnZero(b.DailyPurchase)), 0))
      } else {
        setReportHistory(null);
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [SelectedYear, SelectedMonth]); // placing this will render on state change

  const handleClose = () => {
    setOpenDialogOrderDetails(false);
  };
  return (
    <>
      <br /><br /><br /><br></br>
      <Container className="mt--6" fluid>
        {showNewOrderForm === true ? (
          <>
            <CardHeader>
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Report Details</h3>
                </div>
                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => {
                      setShowNewOrderForm(false);
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <br></br>
            {/*this will create new report*/}
            <NewReport />
          </>
        ) : (
          <>
            {/* header for functionality like summary, view monthly data */}
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h5 className="h3 mb-0">Total Sale: <NumberFormat value={MTDTotalSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  <h5 className="h3 mb-0">Sale-Tax:     <NumberFormat value={MTDTotalSalesTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                </div>
                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => setShowNewOrderForm(true)}
                  >
                    New / Update Report
                  </Button>
                </div>
              </Row>
            </CardHeader><br></br>

            {/*below code will display MTD to summary from report */}
            {ReportHistory !== null ? (
              <>
                {ReportHistory[0] !== undefined ?
                  <>
                    <Row>
                      <Col xl="4">
                        <Card>
                          <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                              <Col xl="6">
                                <h5 className="h3 mb-0">Tax Sale: <NumberFormat value={MTDTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Non-Tax Sale: <NumberFormat value={MTDNonTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Deli Sale: <NumberFormat value={MTDDeli.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                              <Col xl="6">
                                <h5 className="h3 mb-0">Beer Sale: <NumberFormat value={MTDBeer.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Cigratte Sale: <NumberFormat value={MTDCigratte.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Other Food: <NumberFormat value={MTDOtherFood.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                            </Row>
                          </CardHeader>
                        </Card>
                      </Col>
                      <Col xl="4">
                        <Card>
                          <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                              <Col>
                                <h5 className="h3 mb-0">Purchase: <NumberFormat value={MTDPurchase.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Expense: <NumberFormat value={MTDExpense.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Over / Short: <NumberFormat value={MTDTotalOverShort.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                            </Row>
                          </CardHeader>
                        </Card>
                      </Col>
                      <Col xl="4">
                        <Card>
                          <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                              <Col>
                                <h5 className="h3 mb-0">Money Order: <NumberFormat value={MTDMoneyOrder.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Check Cashing: <NumberFormat value={MTDCheckCashing.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Western Union: <NumberFormat value={MTDWesternUnion.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                            </Row>
                          </CardHeader>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4">
                        <Card>
                          <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                              <Col xl="6" >
                                <h5 className="h3 mb-0">Gas Gallon: <NumberFormat value={MTDTotalGasGallon.toFixed(2)} displayType={'text'} thousandSeparator={true} /></h5>
                                <h5 className="h3 mb-0">Gas Sale: <NumberFormat value={MTDTotalGasSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                              <Col xl="6" >
                                <h5 className="h3 mb-0">Diesel Gallon: <NumberFormat value={MTDTotalDieselGallon.toFixed(2)} displayType={'text'} thousandSeparator={true} /></h5>
                                <h5 className="h3 mb-0">Diesel Sale: <NumberFormat value={MTDTotalDieselSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                            </Row>
                          </CardHeader>
                        </Card>
                      </Col>
                      <Col xl="4">
                        <Card>
                          <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                              <Col xl="6">
                                <h5 className="h3 mb-0">Lottery Sale: <NumberFormat value={MTDLotterySale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Lottery Cash: <NumberFormat value={MTDLotteryCash.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                              <Col xl="6">
                                <h5 className="h3 mb-0">Lotto Sale: <NumberFormat value={MTDLottoSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Lotto Cash: <NumberFormat value={MTDLottoCash.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                            </Row>
                          </CardHeader>
                        </Card>
                      </Col>
                      <Col xl="4">
                        <Card>
                          <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                              <Col>
                                <h5 className="h3 mb-0">Credit / Debit Card: <NumberFormat value={MTDTotalCreditDebit.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                                <h5 className="h3 mb-0">Cash: <NumberFormat value={MTDTotalCash.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              </Col>
                            </Row>
                          </CardHeader>
                        </Card>
                      </Col>
                    </Row>
                  </> :
                  <>
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  </>
                }
              </>
            ) : null}

            {/*below code will display the table */}
            {ReportHistory !== null ? (
              <>
                {ReportHistory[0] !== undefined ?
                  <>
                    <MaterialTable
                      icons={tableIcons}
                      title="Summary Reports"
                      columns={SummaryDailyReport.map((c) => ({ ...c, tableData: undefined }))}
                      data={ReportHistory}
                      actions={[
                        {
                          icon: MoreVertIcon,
                          tooltip: "Detailed Information",
                          onClick: (event, rowData) => {
                            setOpenDialogOrderDetails(true);
                            setSelectedRowData(rowData);
                          },
                        },
                      ]}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                    <br></br>
                    <MaterialTable
                      icons={tableIcons}
                      title="Grocery Summary Reports"
                      columns={SummaryDailyGroceryReport.map((c) => ({ ...c, tableData: undefined }))}
                      data={ReportHistory}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                    <br></br>
                    <MaterialTable
                      icons={tableIcons}
                      title="Gas Summary Reports"
                      columns={SummaryDailyGasReport.map((c) => ({ ...c, tableData: undefined }))}
                      data={ReportHistory}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                    <br></br>
                    <MaterialTable
                      icons={tableIcons}
                      title="Lottery / Lotto Summary Reports"
                      columns={SummaryDailyLotteryReport.map((c) => ({ ...c, tableData: undefined }))}
                      data={ReportHistory}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                  </> :
                  <></>
                }
              </>
            ) :
              <>
                <Alert severity="info">
                  There is NO Data for Selected Month & Year
                </Alert>
              </>
            }

            {/*below code will print the report*/}
            {ReportHistory !== null ? (
              <>
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  open={openDialogOrderDetails}
                  onClose={handleClose}
                  aria-labelledby="max-width-dialog-title"
                >
                  <DialogTitle
                    id="max-width-dialog-title"
                    onClose={handleClose}
                  >
                    <Row className="align-items-center">
                      <div className="col">
                        Report Date #{selectedRowData.Date}
                      </div>
                      <div className="col text-right">
                        <Button
                          classes={{
                            root: classes.root, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                          onClick={handleClose}
                        >
                          Close
                        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                    </Row>
                  </DialogTitle>
                  {/*DialogContent will be imported from viewreport */}
                  <ViewReport
                    DataRow={selectedRowData}
                  />
                </Dialog>
              </>
            ) : null}
          </>
        )}
      </Container>
      <br></br>
    </>
  );
}
export default Report;
