/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
import {
    Row,
    Card,
    CardHeader,
} from "reactstrap";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import {
    DailyReport,
    ExpenseReport,
} from "../CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Alert from '@mui/material/Alert';
import MaterialTable from "material-table";
import { tableIcons } from "../CustomComponent/reuseableFn";
import TableHeaderColor from "../CustomComponent/Colors";

export function UpdateExpense() {
    const [ExpenseHistory, setExpenseHistory] = React.useState([]);
    const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
    // variable to save Expense data
    //grocery
    const [ExpenseDate, setExpenseDate] = React.useState(new Date());
    const [SelectedRow, setSelectedRow] = React.useState();

    let date = ExpenseDate.getDate();
    let month = ExpenseDate.getMonth() + 1;
    let year = ExpenseDate.getFullYear();
    var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

    const [columns] = React.useState([
        { title: 'ID', field: 'id', editable: 'never' },
        { title: 'Purchase Date', field: 'Date', type: 'date', editable: 'never' },
        { title: 'Company Name', field: 'Name' },
        { title: 'Amount', field: 'Amount', type: 'currency' },
        {
            title: "Payment Type", field: "PaymentType",
            lookup: { "Cash": "Cash", "Check": "Check", "Money-Order": "Money-Order", "Card": "Card" }
        },
        { title: 'Note', field: 'Note' },
    ]);

    //initialize to get data of all customers, order history
    useEffect(() => {
        let date1 = ExpenseDate.getDate();
        let month1 = ExpenseDate.getMonth() + 1;
        let year1 = ExpenseDate.getFullYear();
        var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

        const dbRefOrder = firebase.database().ref(ExpenseReport + '/' + username1 + "/" + year1 + "/" + month1 + "/" + date1);
        dbRefOrder.get().then((snapshot) => {
            let users = [];
            //let IDRefactor = [], NameRefactor = []
            if (snapshot.exists()) {
                //console.log("data available");
                snapshot.forEach((snap) => {
                    //console.log(snap.key)
                    users.push({ id: snap.key, ...snap.val() });
                });
                console.log(users)
                setExpenseHistory(users)
            } else {
                setExpenseHistory(null)
                console.log("No data available");
            }
        })
            .catch((error) => {
                console.error(error);
            });

    }, [ExpenseDate]); // placing this will render on state change

    //console.log(ExpenseHistory)
    //console.log(SelectedRow)
    if (SelectedRow !== undefined) {
        firebase.database().ref(ExpenseReport + '/' + username + "/" + year + "/" + month + "/" + date + "/" + SelectedRow.id).set({
            Date: SelectedRow.Date,
            Name: SelectedRow.Name,
            Amount: SelectedRow.Amount,
            PaymentType: SelectedRow.PaymentType,
            Note: SelectedRow.Note
        })
        firebase.database().ref(DailyReport + '/' + username + "/" + year + "/" + month + "/" + date).update({
            DailyExpense: ExpenseHistory.reduce((a, b) => a = a + Number(b.Amount), 0),
        })
    }
    return (
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Update Expense Details</h3>
                        </div>
                        {
                            ShowUpdateSuccessMessage === true ?
                                <>
                                    <Alert severity="success">
                                        Expense Updated Successfully for {month + "/" + date + "/" + year}
                                    </Alert>
                                </> : null
                        }
                        <div className="col text-right">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    label="Expense Date"
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    value={ExpenseDate}
                                    onChange={(newValue) => {
                                        setExpenseDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </Row>
                </CardHeader>
            </Card>
            {ExpenseHistory !== null ?
                <>
                    <MaterialTable
                        icons={tableIcons}
                        title="Expense"
                        columns={columns.map((c) => ({ ...c, tableData: undefined }))}
                        data={ExpenseHistory}
                        onRowSelected={(evt, selectedRow) => setSelectedRow(selectedRow)}
                        options={{
                            exportButton: true,
                            headerStyle: {
                                backgroundColor: TableHeaderColor,
                                color: "#000000",
                                textColor: "#000000",
                            },
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...ExpenseHistory];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setExpenseHistory([...dataUpdate]);
                                        setSelectedRow(dataUpdate[index])
                                        setShowUpdateSuccessMessage(true)
                                        setTimeout(function () {
                                            setShowUpdateSuccessMessage(false)
                                        }, 5000);//wait 5 seconds
                                        resolve();
                                    }, 1000)
                                }),
                            /* onRowDelete: oldData =>
                                 new Promise((resolve, reject) => {
                                     setTimeout(() => {
                                         const dataDelete = [...ExpenseHistory];
                                         const index = oldData.tableData.id;
                                         dataDelete.splice(index, 1);
                                         setExpenseHistory([...dataDelete]);
                                         setShowUpdateSuccessMessage(true)
                                         setTimeout(function () {
                                             setShowUpdateSuccessMessage(false)
                                         }, 5000);//wait 5 seconds
                                         resolve();
                                     }, 1000)
                                 }),*/
                        }}
                    />
                </>
                :
                <>
                    <Alert severity="info">
                        There is NO Data for Selected Date: {month + "/" + date + "/" + year}
                    </Alert>
                </>
            }
        </>
    );
}
export default UpdateExpense;
