
function test() {

}
export default test();
//Note change last four character for 
// 1. testing of the application by "Test" 
// 2. production of the application by "Prod"
export const CustomersInfo = 'CustomerInfo';
export const DailyReport = 'DailyReport';
export const ExpenseReport = 'ExpenseReport';
export const PurchaseReport = 'PurchaseReport';
export const LotteryReport = 'LotteryReport';
export const GasReport = 'GasReport';
export const PermitsHistory = 'PermitsHistory';
export const WarrentyHistory = 'WarrentyHistory';
export const ProfileData = 'ProfileAndPermission';