/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
  Col,
  Row,
  Form,
  CardHeader,
  CardBody,
  Card,
  Container,
} from "reactstrap";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import {
  DailyReport,
  PurchaseReport,
} from "./CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import FormatDateMMDDYYY, { tableIcons, useStyles } from "./CustomComponent/reuseableFn";
import Button from "@material-ui/core/Button";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import UpdatePurchase from "./SubPurchase/UpdatePurchase";
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableHeaderColor from "./CustomComponent/Colors";
import Alert from '@mui/material/Alert';

export function Purchase() {
  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const [PurchaseHistory, setPurchaseHistory] = React.useState([])
  const [PurchaseExistingEdit, setPurchaseExistingEdit] = React.useState(false);
  const [ViewSummaryPurchase, setViewSummaryPurchase] = React.useState(true);
  const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
  // variable to save Purchase data
  //Purchase
  const [PurchaseDate, setPurchaseDate] = React.useState(new Date());
  const [PurchaseName, setPurchaseName] = React.useState("");
  const [PurchaseAmount, setPurchaseAmount] = React.useState(0);
  const [PurchasePaymentType, setPurchasePaymentType] = React.useState("Cash");
  const [MTDTotalPurchase, setMTDTotalPurchase] = React.useState(0);

  //year, month, date selected to filter the data
  let date = PurchaseDate.getDate();
  let month = PurchaseDate.getMonth() + 1;
  let year = PurchaseDate.getFullYear();

  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');
  const classes = useStyles();

  // to filter the data before fetch
  var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
  // column to show on invoice table and create row radomly

  const [SummaryDailyPurchase] = useState([
    { title: "Date", field: "Date", type: "date" },
    { title: "Purchase Name", field: "Name", },
    { title: "Amount", field: "Amount", type: "currency" },
    { title: "Payment Type", field: "PaymentType", },
  ]);

  //initialize to get data of all customers, order history
  useEffect(() => {
    let date1 = PurchaseDate.getDate();
    let month1 = PurchaseDate.getMonth() + 1;
    let year1 = PurchaseDate.getFullYear();
    var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const dbRefOrder = firebase.database().ref(PurchaseReport + '/' + username1 + "/" + SelectedYear + "/" + SelectedMonth);
    dbRefOrder.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push(snap.val());
        });
        //console.log(users)
        var formatList = []
        users.map(a => {
          //console.log(Object.values(a))
          Object.values(a).map(b => {
            formatList.push(b)
            return null
          })
          return null
        })
        setPurchaseHistory(formatList)
        setMTDTotalPurchase(formatList.reduce((a, b) => a = a + Number(b.Amount), 0))
      } else {
        setMTDTotalPurchase(0)
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
    firebase.database().ref(DailyReport + '/' + username1 + "/" + year1 + "/" + month1 + "/" + date1).update({
      Date: FormatDateMMDDYYY(PurchaseDate),
      DailyPurchase: PurchaseHistory.filter(a => a.Date === FormatDateMMDDYYY(PurchaseDate)).reduce((a, b) => a = a + Number(b.Amount), 0),
    })
  }, [ShowUpdateSuccessMessage, SelectedMonth, SelectedYear, PurchaseDate, PurchaseHistory]); // placing this will render on state change
  //console.log(PurchaseHistory)
  //send data to database and create Purchase for customers
  const handleOrderForm = (event) => {
    event.preventDefault();
    // generate unique Purchase number
    let NewDate = month + "/" + date + "/" + year
    console.log(PurchaseHistory)
    firebase.database().ref(PurchaseReport + "/" + username + "/" + year + "/" + month + "/" + date).push({
      Date: NewDate,
      Name: PurchaseName,
      Amount: PurchaseAmount,
      PaymentType: PurchasePaymentType
    });
    setShowUpdateSuccessMessage(true)
    setTimeout(function () {
      setShowUpdateSuccessMessage(false)
    }, 2000);//wait 5 seconds
  };

  const handleEditExistingPurchase = () => {
    setViewSummaryPurchase(false);
    setPurchaseExistingEdit(true)
  };
  const handleSummaryPurchase = () => {
    setViewSummaryPurchase(true);
    setPurchaseExistingEdit(false)
  };
  return (
    <>
      <br /><br /><br /><br></br>
      <Container className="mt--6" fluid>
        {showNewOrderForm === true ? (
          <>
            <CardHeader>
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Daily Purchase Details</h3>
                </div>
                <div className="col">
                  {
                    ShowUpdateSuccessMessage === true ?
                      <>
                        <Alert severity="success">
                          Expense Added Successfully for {month + "/" + date + "/" + year}
                        </Alert>
                      </> : null
                  }
                </div>
                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => {
                      setShowNewOrderForm(false);
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <br></br>
            <Form role="form" onSubmit={handleOrderForm}>
              <Row form>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Summary</h3>
                        </div>
                        <div className="col text-right">
                          <Button
                            classes={{
                              root: classes.root, // class name, e.g. `classes-nesting-root-x`
                              label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                            type="submit"
                          >
                            Submit Purchase
                          </Button>
                        </div>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Row>
                        <Col sm={3}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disableFuture
                              label="Purchase Date"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              value={PurchaseDate}
                              onChange={(newValue) => {
                                setPurchaseDate(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Col>
                        <Col sm={3}>
                          <TextField
                            label="Purchase Name"
                            value={PurchaseName}
                            variant="standard"
                            onChange={a => setPurchaseName(a.target.value)}
                          />
                        </Col>
                        <Col sm={3}>
                          <CurrencyTextField
                            label="Amount"
                            value={PurchaseAmount}
                            variant="standard"
                            currencySymbol="$"
                            onChange={(event, value) => setPurchaseAmount(value)}
                          />
                        </Col>
                        <Col sm={3}>
                          <FormControl required sx={{ width: 150 }}>
                            <InputLabel id="demo-simple-select-required-label">Payment Type</InputLabel>
                            <Select
                              type="select"
                              defaultValue="Cash"
                              value={PurchasePaymentType}
                              label="Payment Type"
                              onChange={a => setPurchasePaymentType(a.target.value)}
                            >
                              <MenuItem value="Cash">Cash</MenuItem><br></br>
                              <MenuItem value="Check">Check</MenuItem><br></br>
                              <MenuItem value="Money-Order">Money-Order</MenuItem><br></br>
                              <MenuItem value="Card">Card</MenuItem><br></br>
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            {/* header for functionality like summary, update Purchase, create new Purchase */}
            <CardHeader className="border-0">
              <Row className="align-items-center">
                {ViewSummaryPurchase === true ?
                  <Row>
                    <div className="col">
                      <h5 className="h3 mb-0">Total Purchase MTD: <NumberFormat value={MTDTotalPurchase.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                    </div>
                  </Row>
                  : null}

                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={handleSummaryPurchase}
                  >
                    Purchase Summary
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={handleEditExistingPurchase}
                  >
                    Update Purchase
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => setShowNewOrderForm(true)}
                  >
                    New Purchase
                  </Button>
                </div>
              </Row>

            </CardHeader><br></br>

            {/*below code will display the table */}
            {PurchaseHistory !== null ? (
              <>
                {ViewSummaryPurchase === true ?
                  <>
                    <MaterialTable
                      icons={tableIcons}
                      title="Summary Purchases"
                      columns={SummaryDailyPurchase.map((c) => ({ ...c, tableData: undefined }))}
                      data={PurchaseHistory}
                      onChangeRowsPerPage={10}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                  </> : null}
                {PurchaseExistingEdit === true ?
                  <>
                    <UpdatePurchase />
                  </> : null}
              </>
            ) : <>
              <Alert severity="info">
                There is NO Data for Selected Month & Year
              </Alert>
            </>}
          </>
        )}
      </Container>
      <br></br>
    </>
  );
}
export default Purchase;
