/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
  Col,
  Row,
  Container,
  Card,
  CardHeader, CardBody,
} from "reactstrap";
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// core components
import firebase from "firebase/app";
import "firebase/auth";
import { DailyReport } from "./CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import { CustomTooltipGas, CustomTooltipGrocery, filterUsername, tableIcons, UndefinedReturnZero, useStyles } from "./CustomComponent/reuseableFn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import TableHeaderColor from "./CustomComponent/Colors";
import ViewReport from "./SubReport/ViewReport";
import { SummaryDailyReport } from "./CustomComponent/TableColumn";

export function Dashboard() {
  const [ReportHistory, setReportHistory] = React.useState(undefined);
  const [openDialogOrderDetails, setOpenDialogOrderDetails] = React.useState(false);
  const [ViewType, setViewType] = React.useState('0');
  const [ChartType, setChartType] = React.useState('1');
  // variable to save report data
  //MTD report info
  const [MTDTotalExpensePurchase, setMTDTotalExpensePurchase] = React.useState(0);
  const [MTDTotalOverShort, setMTDTotalOverShort] = React.useState(0);
  const [MTDTotalCreditDebit, setMTDTotalCreditDebit] = React.useState(0);
  const [MTDTotalCash, setMTDTotalCash] = React.useState(0);
  //grocery mtd
  const [MTDTotalGrocery, setMTDTotalGrocery] = React.useState(0);
  const [MTDTotalSalesTax, setMTDTotalSalesTax] = React.useState(0);
  const [MTDTotalLotteryLotto, setMTDTotalLotteryLotto] = React.useState(0);
  const [MTDTotalSale, setMTDTotalSale] = React.useState(0);
  const [MTDTax, setMTDTax] = React.useState(0);
  const [MTDNonTax, setMTDNonTax] = React.useState(0);
  const [MTDBeer, setMTDBeer] = React.useState(0);
  const [MTDCig, setMTDCig] = React.useState(0);
  const [MTDOtherTax, setMTDOtherTax] = React.useState(0);
  const [MTDOtherNonTax, setMTDOtherNonTax] = React.useState(0);
  // gas mtd
  const [MTDTotalGasGallon, setMTDTotalGasGallon] = React.useState(0);
  const [MTDTotalGasSale, setMTDTotalGasSale] = React.useState(0);
  const [MTDTotalDieselGallon, setMTDTotalDieselGallon] = React.useState(0);
  const [MTDTotalDieselSale, setMTDTotalDieselSale] = React.useState(0);

  //year, month, date selected to filter the data
  const [selectedRowData, setSelectedRowData] = React.useState({});
  const classes = useStyles();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xl");
  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');

  //initialize to get data of all customers, order history
  useEffect(() => {

    const dbRefOrder = firebase.database().ref(DailyReport + '/' + filterUsername() + "/" + SelectedYear + "/" + SelectedMonth);
    dbRefOrder.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push(snap.val());
        });
        setReportHistory(users);
        setMTDTotalSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.TotalSale)), 0))
        setMTDTotalSalesTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.TotalTaxCollected)), 0))
        setMTDTotalCreditDebit(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.CreditDebitCard)), 0))
        setMTDTotalCash(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Cash)), 0))
        setMTDTotalGasGallon(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasPremiumGallon)) + Number(UndefinedReturnZero(b.GasRegularGallon)), 0))
        setMTDTotalGasSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasPremiumSale)) + Number(UndefinedReturnZero(b.GasRegularSale)), 0))
        setMTDTotalDieselSale(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasDieselSale)), 0))
        setMTDTotalDieselGallon(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.GasDieselGallon)), 0))
        setMTDTotalLotteryLotto(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.LotteryLottoSale)), 0))
        setMTDTotalGrocery(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.TotalGrocery)), 0))
        setMTDTotalExpensePurchase(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.ExpensePurchase)), 0))
        setMTDTotalOverShort(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.OverShortShift)), 0))
        setMTDTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Tax)), 0))
        setMTDNonTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.NonTax)), 0))
        setMTDBeer(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Beer)), 0))
        setMTDCig(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.Cigratte)), 0))
        setMTDOtherNonTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.OtherNonTax)), 0))
        setMTDOtherTax(users.reduce((a, b) => a = a + Number(UndefinedReturnZero(b.OtherTax)), 0))
      } else {
        setReportHistory(undefined);
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [SelectedMonth, SelectedYear]); // placing this will render on state change

  // below condition to format data for line chart
  var GroceryChart = [], GasDailyDataChart = [];
  if (ReportHistory !== undefined) {
    ReportHistory.map((a, index) => {
      //grocery chart
      GroceryChart.push({
        Date: a.Date,
        Tax: a.Tax,
        NonTax: a.NonTax,
        Beer: a.Beer,
        Cigratte: a.Cigratte,
        OtherNonTax: a.OtherNonTax,
        OtherTax: a.OtherTax
      })
      //gas chart
      GasDailyDataChart.push({
        Date: a.Date,
        RegularGallon: a.GasRegularGallon,
        RegularTotalSale: a.GasRegularSale,
        RegularPrice: a.GasRegularPrice,
        PremiumGallon: a.GasPremiumGallon,
        PremiumTotalSale: a.GasPremiumSale,
        PremiumPrice: a.GasPremiumPrice,
        DieselGallon: a.GasDieselGallon,
        DieselTotalSale: a.GasDieselSale,
        DieselPrice: a.GasDieselPrice,
      })
      return null
    })
  }

  const handleClose = () => {
    setOpenDialogOrderDetails(false);
  };

  return (
    <>
      <br /><br /><br /><br></br>
      <Container className="mt--6" fluid>
        <>
          <CardHeader className="border-0">
            <Row>
              <Col md={2}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">PLease Select View</FormLabel>
                  <RadioGroup
                    row
                    value={ViewType}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(event, value) => setViewType(value)}
                  >
                    <FormControlLabel value={'0'} control={<Radio />} label="Graph" />
                    <FormControlLabel value={'1'} control={<Radio />} label="Calender" />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={2}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Graph View</FormLabel>
                  <RadioGroup
                    row
                    value={ChartType}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(event, value) => setChartType(value)}
                  >
                    <FormControlLabel value={'1'} control={<Radio />} label="Bar" />
                    <FormControlLabel value={'0'} control={<Radio />} label="Line " />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={2}>
                <h5 className="h3 mb-0">Total Sale: <NumberFormat value={MTDTotalSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                <h5 className="h3 mb-0">Sale-Tax: <NumberFormat value={MTDTotalSalesTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
              </Col>
              <Col md={2}>
                <h5 className="h3 mb-0">Lottery / Lotto: <NumberFormat value={MTDTotalLotteryLotto.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                <h5 className="h3 mb-0">Grocery: <NumberFormat value={MTDTotalGrocery.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
              </Col>
              <Col md={2}>
                <h5 className="h3 mb-0">Credit / Debit Card: <NumberFormat value={MTDTotalCreditDebit.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                <h5 className="h3 mb-0">Cash: <NumberFormat value={MTDTotalCash.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
              </Col>
              <Col md={2}>
                <h5 className="h3 mb-0">Over / Short: <NumberFormat value={MTDTotalOverShort.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                <h5 className="h3 mb-0">Expense / Purchase: <NumberFormat value={MTDTotalExpensePurchase.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
              </Col>
            </Row>

          </CardHeader><br></br>
          {/*below code will display MTD to summary from report */}
          {ReportHistory !== undefined ? (
            <>
              {ViewType === '0' ?
                <>
                  <Row>
                    <Col md={6}>
                      <Card>
                        <CardHeader>
                          <Row className="align-items-center">
                            <Col md={6}>
                              <h5 className="h3 mb-0">Gas Gallon: <NumberFormat value={MTDTotalGasGallon.toFixed(2)} displayType={'text'} thousandSeparator={true} /></h5>
                              <h5 className="h3 mb-0">Gas Sale: <NumberFormat value={MTDTotalGasSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                            </Col>
                            <Col md={6}>
                              <h5 className="h3 mb-0">Diesel Gallon: <NumberFormat value={MTDTotalDieselGallon.toFixed(2)} displayType={'text'} thousandSeparator={true} /></h5>
                              <h5 className="h3 mb-0">Diesel Sale: <NumberFormat value={MTDTotalDieselSale.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {ChartType === '0' ?
                            <>
                              <ResponsiveContainer width="100%" height={420}>
                                <LineChart data={GasDailyDataChart}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="Date" />
                                  <YAxis />
                                  <Tooltip content={<CustomTooltipGas />} />
                                  <Legend />
                                  <Line type="monotone" dataKey="RegularGallon" stroke="#ff6161" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="PremiumGallon" stroke="#576bff" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="DieselGallon" stroke="#4dab5b" activeDot={{ r: 8 }} />
                                </LineChart>
                              </ResponsiveContainer>
                            </> : ChartType === '1' ?
                              <>
                                <ResponsiveContainer width="100%" height={420}>
                                  <BarChart data={GasDailyDataChart}                        >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="Date" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltipGas />} />
                                    <Legend />
                                    <Bar dataKey="RegularGallon" stackId="a" fill="#ff6161" barSize={20} />
                                    <Bar dataKey="PremiumGallon" stackId="a" fill="#576bff" barSize={20} />
                                    <Bar dataKey="DieselGallon" stackId="a" fill="#4dab5b" barSize={20} />
                                  </BarChart>
                                </ResponsiveContainer>
                              </> : null}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardHeader>
                          <Row className="align-items-center">
                            <Col md={4}>
                              <h5 className="h3 mb-0">Tax: <NumberFormat value={MTDTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              <h5 className="h3 mb-0">Other Tax: <NumberFormat value={MTDOtherTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                            </Col>
                            <Col md={4}>
                              <h5 className="h3 mb-0">Non-Tax: <NumberFormat value={MTDNonTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              <h5 className="h3 mb-0">Other Non-Tax: <NumberFormat value={MTDOtherNonTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                            </Col>
                            <Col md={4}>
                              <h5 className="h3 mb-0">Beer: <NumberFormat value={MTDBeer.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                              <h5 className="h3 mb-0">Cigarette: <NumberFormat value={MTDCig.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {ChartType === '0' ?
                            <>
                              <ResponsiveContainer width="100%" height={420}>
                                <LineChart data={GroceryChart}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="Date" />
                                  <YAxis />
                                  <Tooltip content={<CustomTooltipGrocery />} />
                                  <Legend />
                                  <Line type="monotone" dataKey="Tax" stroke="#576bff" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="NonTax" stroke="#4dab5b" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="Beer" stroke="#ff6161" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="Cigratte" stroke="#ebd50c" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="OtherNonTax" stroke="#0680c2" activeDot={{ r: 8 }} />
                                  <Line type="monotone" dataKey="OtherTax" stroke="#965bc9" activeDot={{ r: 8 }} />
                                </LineChart>
                              </ResponsiveContainer>
                            </> : ChartType === '1' ?
                              <>
                                <ResponsiveContainer width="100%" height={420}>
                                  <BarChart data={GroceryChart}                        >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="Date" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltipGrocery />} />
                                    <Legend />
                                    <Bar dataKey="Tax" stackId="a" fill="#576bff" barSize={20} />
                                    <Bar dataKey="NonTax" stackId="a" fill="#4dab5b" barSize={20} />
                                    <Bar dataKey="Beer" stackId="a" fill="#ff6161" barSize={20} />
                                    <Bar dataKey="Cigratte" stackId="a" fill="#ebd50c" barSize={20} />
                                    <Bar dataKey="OtherNonTax" stackId="a" fill="#0680c2" barSize={20} />
                                    <Bar dataKey="OtherTax" stackId="a" fill="#965bc9" barSize={20} />
                                  </BarChart>
                                </ResponsiveContainer>
                              </> : null}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </> : ViewType === '1' ?
                  <>
                  </> : null}
            </>
          ) :
            <>
              <Alert severity="info">
                There is NO Data for Selected Month & Year
              </Alert>
            </>}

          {/*below code will display the table */}
          {ReportHistory !== undefined ? (
            <>
              <MaterialTable
                icons={tableIcons}
                title="Summary Reports"
                columns={SummaryDailyReport.map((c) => ({ ...c, tableData: undefined }))}
                data={ReportHistory}
                actions={[
                  {
                    icon: MoreVertIcon,
                    tooltip: "Detailed Information",
                    onClick: (event, rowData) => {
                      setOpenDialogOrderDetails(true);
                      setSelectedRowData(rowData);
                    },
                  },
                ]}
                options={{
                  exportButton: true,
                  headerStyle: {
                    backgroundColor: TableHeaderColor,
                    color: "#000000",
                    textColor: "#000000",
                  },
                }}
              />
            </>
          ) : null}

          {/*below code will print the report*/}
          {ReportHistory !== null ? (
            <>
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openDialogOrderDetails}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle
                  id="max-width-dialog-title"
                  onClose={handleClose}
                >
                  <Row className="align-items-center">
                    <div className="col">
                      Report Date #{selectedRowData.Date}
                    </div>
                    <div className="col text-right">
                      <Button
                        classes={{
                          root: classes.root, // class name, e.g. `classes-nesting-root-x`
                          label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </Row>
                </DialogTitle>
                {/*DialogContent will be imported from viewreport */}
                <ViewReport
                  DataRow={selectedRowData}
                />
              </Dialog>
            </>
          ) : null}
        </>
      </Container>
      <br></br>
    </>
  );
}
export default Dashboard;
