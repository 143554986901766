import firebase from "firebase/app";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
function handleLogout() {
  firebase.auth().signOut().then(() => {
    // Sign-out successful.
    console.log('sign out successfully')
    //window.localStorage.removeItem("auth")
    //window.localStorage.removeItem("token")
    localStorage.removeItem('email')
    localStorage.removeItem('Year')
    localStorage.removeItem('Month')
    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </BrowserRouter>,
      document.getElementById("root")
    );
    //window.location.reload();
  }).catch((error) => {
    // An error happened.
  });
}
export default handleLogout;