/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
import {
    Col,
    Row,
    Form,
    Card,
    CardHeader,
    CardBody,
} from "reactstrap";
import Button from "@material-ui/core/Button";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import {
    DailyReport,
    LotteryReport,
} from "../CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Alert from '@mui/material/Alert';
import MaterialTable from "material-table";
import { tableIcons, useStyles } from "../CustomComponent/reuseableFn";
import TableHeaderColor from "../CustomComponent/Colors";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'


export function UpdateLottery() {
    const [LotteryHistory, setLotteryHistory] = React.useState();
    const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
    // variable to save Lottery data
    //grocery
    const [LotteryDate, setLotteryDate] = React.useState(new Date());
    const [SelectedRow, setSelectedRow] = React.useState();
    const [ScractOffCash, setScractOffCash] = React.useState(0);
    const [LottoSale, setLottoSale] = React.useState(0);
    const [LottoCash, setLottoCash] = React.useState(0);

    let date = LotteryDate.getDate();
    let month = LotteryDate.getMonth() + 1;
    let year = LotteryDate.getFullYear();
    var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const classes = useStyles();

    const [columns] = React.useState([
        { title: "Date", field: "Date", type: "date", editable: 'never' },
        { title: "Box No", field: "BoxNo", type: "number", editable: 'never' },
        { title: "Game No", field: "GameNo", type: "number", editable: 'never' },
        { title: "Game Price", field: "GamePrice", type: "currency", editable: 'never' },
        { title: "Start", field: "StartOn", type: "number", },
        { title: "End", field: "EndOn", type: "number", },
    ]);

    //initialize to get data of all customers, order history
    useEffect(() => {
        let date1 = LotteryDate.getDate();
        let month1 = LotteryDate.getMonth() + 1;
        let year1 = LotteryDate.getFullYear();
        var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
        const dbRefOrder = firebase.database().ref(LotteryReport + "/" + username1 + "/TicketSoldDetail/" + year1 + "/" + month1 + "/" + date1);
        dbRefOrder.get().then((snapshot) => {
            let users = [];
            if (snapshot.exists()) {
                //console.log("data available");
                snapshot.forEach((snap) => {
                    //console.log(snap.key)
                    users.push({ id: snap.key, ...snap.val() });
                });
                //console.log(users)
                setLotteryHistory(users)
            } else {
                setLotteryHistory(undefined)
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
        const dbRefTicketSoldSummary = firebase.database().ref(LotteryReport + '/' + username1 + "/TicketSoldSummary/" + year1 + "/" + month1 + "/" + date1);
        dbRefTicketSoldSummary.get().then((snapshot) => {
            let users = [];
            if (snapshot.exists()) {
                users.push(snapshot.toJSON());
                //console.log(users[0])
                setScractOffCash(users[0].ScractOffCash)
                setLottoCash(users[0].LottoCash)
                setLottoSale(users[0].LottoSale)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [LotteryDate, SelectedRow]); // placing this will render on state change

    //console.log(LotteryHistory)
    //console.log(SelectedRow)
    if (SelectedRow !== undefined) {
        firebase.database().ref(LotteryReport + "/" + username + "/TicketSoldDetail/" + year + "/" + month + "/" + date + "/" + SelectedRow.id).set({
            BoxId: SelectedRow.BoxId,
            BoxNo: SelectedRow.BoxNo,
            GameName: SelectedRow.GameName,
            Date: month + "/" + date + "/" + year,
            GameNo: SelectedRow.GameNo,
            GamePrice: SelectedRow.GamePrice,
            PackSize: SelectedRow.PackSize,
            StartOn: SelectedRow.StartOn,
            EndOn: SelectedRow.EndOn,
            QuantitySold: SelectedRow.EndOn - SelectedRow.StartOn,
            TotalAmount: (SelectedRow.EndOn - SelectedRow.StartOn) * SelectedRow.GamePrice,
        })
        firebase.database().ref(LotteryReport + "/" + username + "/TicketSoldSummary/" + year + "/" + month + "/" + date).update({
            Date: month + "/" + date + "/" + year,
            ScractOffQuantity: LotteryHistory.reduce((a, b) => a = a + Number(b.QuantitySold), 0),
            ScractOffAmount: LotteryHistory.reduce((a, b) => a = a + Number(b.TotalAmount), 0),
            LotteryLottoSale: LottoSale + LotteryHistory.reduce((a, b) => a = a + Number(b.TotalAmount), 0),
            LotteryLottoCash: LottoCash + ScractOffCash,
        });
        // add data to report
        firebase.database().ref(DailyReport + '/' + username + "/" + year + "/" + month + "/" + date).update({
            Date: month + "/" + date + "/" + year,
            LotterySale: LotteryHistory.reduce((a, b) => a = a + Number(b.TotalAmount), 0),
            LotteryDiff: LotteryHistory.reduce((a, b) => a = a + Number(b.TotalAmount), 0) - ScractOffCash,
            LotteryLottoSale: LottoSale + LotteryHistory.reduce((a, b) => a = a + Number(b.TotalAmount), 0),
            LotteryLottoCash: LottoCash + ScractOffCash,
        })
    }

    const handleUpdateLottery = (event) => {
        event.preventDefault();
        firebase.database().ref(LotteryReport + "/" + username + "/TicketSoldSummary/" + year + "/" + month + "/" + date).update({
            Date: month + "/" + date + "/" + year,
            ScractOffCash: ScractOffCash,
            LottoSale: LottoSale,
            LottoCash: LottoCash,
        });
        // add data to report
        firebase.database().ref(DailyReport + '/' + username + "/" + year + "/" + month + "/" + date).update({
            Date: month + "/" + date + "/" + year,
            LotteryCash: ScractOffCash,
            LottoCash: LottoCash,
            LottoSale: LottoSale,
            LottoDiff: LottoSale - LottoCash,
            LotteryLottoSale: LottoSale + LotteryHistory.reduce((a, b) => a = a + Number(b.TotalAmount), 0),
            LotteryLottoCash: LottoCash + ScractOffCash,
        })
        setShowUpdateSuccessMessage(true)
        setTimeout(function () {
            setShowUpdateSuccessMessage(false)
        }, 5000);//wait 5 seconds
    }
    return (
        <>
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Select Date to Update Scratch-Off Details</h3>
                        </div>
                        {
                            ShowUpdateSuccessMessage === true ?
                                <>
                                    <Alert severity="success">
                                        Lottery Updated Successfully for {month + "/" + date + "/" + year}
                                    </Alert>
                                </> : null
                        }
                        <div className="col text-right">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    label="Lottery Date"
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    value={LotteryDate}
                                    onChange={(newValue) => {
                                        setLotteryDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </Row>
                </CardHeader>
            </Card>
            {LotteryHistory !== undefined ?
                <>
                    <Form role="form" onSubmit={handleUpdateLottery}>
                        <Row form>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Sold / Cash Summary</h3>
                                            </div>
                                            <div className="col text-right">
                                                <Button
                                                    classes={{
                                                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                                                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                                    }}
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <Col sm={2}>
                                                <CurrencyTextField
                                                    label="Lotto Sold"
                                                    value={LottoSale}
                                                    variant="standard"
                                                    currencySymbol="$"
                                                    onChange={(event, value) => setLottoSale(value)}
                                                />
                                            </Col>
                                            <Col sm={2}>
                                                <CurrencyTextField
                                                    label="Lotto Cash"
                                                    value={LottoCash}
                                                    variant="standard"
                                                    currencySymbol="$"
                                                    onChange={(event, value) => setLottoCash(value)}
                                                />
                                            </Col>
                                            <Col sm={2}>
                                                <CurrencyTextField
                                                    label="Scratch-Off Cash"
                                                    value={ScractOffCash}
                                                    variant="standard"
                                                    currencySymbol="$"
                                                    onChange={(event, value) => setScractOffCash(value)}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                    <MaterialTable
                        icons={tableIcons}
                        title="Edit Existing Scratch-Off Details"
                        columns={columns.map((c) => ({ ...c, tableData: undefined }))}
                        data={LotteryHistory}
                        onRowSelected={(evt, selectedRow) => setSelectedRow(selectedRow)}
                        options={{
                            exportButton: true,
                            headerStyle: {
                                backgroundColor: TableHeaderColor,
                                color: "#000000",
                                textColor: "#000000",
                            },
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...LotteryHistory];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setLotteryHistory([...dataUpdate]);
                                        setSelectedRow(dataUpdate[index])
                                        setShowUpdateSuccessMessage(true)
                                        setTimeout(function () {
                                            setShowUpdateSuccessMessage(false)
                                        }, 5000);//wait 5 seconds
                                        resolve();
                                    }, 1000)
                                }),
                        }}
                    />
                </>
                :
                <>
                    <Alert severity="info">
                        There is no Lottery Report for Date: {month + "/" + date + "/" + year}
                    </Alert>
                </>
            }
        </>
    );
}
export default UpdateLottery;
