/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import { Row, CardHeader, Container } from 'reactstrap';
// core components
import firebase from 'firebase/app';
import 'firebase/auth';
import Button from '@material-ui/core/Button';
import { LotteryReport } from './CustomComponent/BackendDataConfig';
import 'firebase/firestore';
import 'firebase/database';
import MaterialTable from 'material-table';
import { tableIcons, useStyles } from './CustomComponent/reuseableFn';
import { CurrentGameInBox } from './CustomComponent/TableColumn';
import TableHeaderColor from './CustomComponent/Colors';
import AddGameLottery from './SubLottery/AddGameLottery';

export function LotteryDisplay() {
  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const [GameInBox, setGameInBox] = React.useState([]);

  //CRUD for game in box
  const [SelectedRowAddGameBox, setSelectedRowAddGameBox] = React.useState();
  const [SelectedRowUpdateGameBox, setSelectedRowUpdateGameBox] = React.useState();
  const [SelectedRowDeleteGameBox, setSelectedRowDeleteGameBox] = React.useState();

  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');
  const classes = useStyles();

  // to filter the data before fetch
  var username =
    localStorage.getItem('email') === null
      ? 'kingswayanish'
      : localStorage
          .getItem('email')
          .split('@')[0]
          .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
  // column to show on invoice table and create row radomly

  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 =
      localStorage.getItem('email') === null
        ? 'kingswayanish'
        : localStorage
            .getItem('email')
            .split('@')[0]
            .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const gdbRefGameInBox = firebase.database().ref(LotteryReport + '/' + username1 + '/GameInBox');
    //will fetch ticket in box data
    gdbRefGameInBox
      .get()
      .then((snapshot) => {
        let users = [];
        if (snapshot.exists()) {
          //console.log("data available");
          snapshot.forEach((snap) => {
            users.push({ id: snap.key, ...snap.val() });
          });
          //console.log(users)
          setGameInBox(users);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.log('No data available');
        console.error(error);
      });
  }, [
    SelectedYear,
    SelectedMonth,
    SelectedRowAddGameBox,
    SelectedRowUpdateGameBox,
    SelectedRowDeleteGameBox,
  ]); // placing this will render on state change

  //below conditin will CRUD ticket number into the system
  if (GameInBox[0] !== undefined) {
    if (SelectedRowUpdateGameBox !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/GameInBox/' + SelectedRowUpdateGameBox.id)
        .set({
          BoxNo: SelectedRowUpdateGameBox.BoxNo,
          GameNo: SelectedRowUpdateGameBox.GameNo,
          GameName: SelectedRowUpdateGameBox.GameName,
          GamePrice: SelectedRowUpdateGameBox.GamePrice,
          PackSize: SelectedRowUpdateGameBox.PackSize,
          GameImage: SelectedRowUpdateGameBox.GameImage,
        });
      setSelectedRowUpdateGameBox(undefined);
    } else if (SelectedRowAddGameBox !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/GameInBox')
        .push({
          BoxNo: SelectedRowAddGameBox['BoxNo'],
          GameNo: SelectedRowAddGameBox['GameNo'],
          GameName: SelectedRowAddGameBox['GameName'],
          GamePrice: SelectedRowAddGameBox['GamePrice'],
          PackSize: SelectedRowAddGameBox['PackSize'],
          GameImage: SelectedRowUpdateGameBox.GameImage,
        });
      setSelectedRowAddGameBox(undefined);
    } else if (SelectedRowDeleteGameBox !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/GameInBox/' + SelectedRowDeleteGameBox.id)
        .set(null);
      setSelectedRowDeleteGameBox(undefined);
    }
  }

  return (
    <>
      <br />
      <br />
      <br />
      <br></br>
      <Container className="mt--6" fluid>
        {showNewOrderForm === true ? (
          <>
            <CardHeader>
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Display Game Lottery Details</h3>
                </div>
                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => {
                      setShowNewOrderForm(false);
                    }}>
                    Go Back
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <br></br>
            <AddGameLottery GameInBox={GameInBox} />
          </>
        ) : (
          <>
            <CardHeader className="border-0">
              <div className="col text-left">
                <Button
                  classes={{
                    root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                  }}
                  onClick={() => setShowNewOrderForm(false)}>
                  Game Summary
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  classes={{
                    root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                  }}
                  onClick={() => setShowNewOrderForm(true)}>
                  Add New Game
                </Button>
              </div>
            </CardHeader>
            <br></br>
          </>
        )}
        {/*below code will display table with LotteryDisplay box information*/}
        {showNewOrderForm === false && (
          <MaterialTable
            icons={tableIcons}
            title="Active Game to Display on Screen"
            columns={CurrentGameInBox.map((c) => ({ ...c, tableData: undefined }))}
            data={GameInBox}
            options={{
              exportButton: true,
              headerStyle: {
                backgroundColor: TableHeaderColor,
                color: '#000000',
                textColor: '#000000',
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    setSelectedRowAddGameBox(newData);
                    setGameInBox([...GameInBox, newData]);
                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    const dataUpdate = [...GameInBox];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setGameInBox([...dataUpdate]);
                    setSelectedRowUpdateGameBox(dataUpdate[index]);
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    const dataDelete = [...GameInBox];
                    const index = oldData.tableData.id;
                    const dataRemove = dataDelete.splice(index, 1);
                    setGameInBox([...dataDelete]);
                    setSelectedRowDeleteGameBox(dataRemove[0]);
                    resolve();
                  }, 1000);
                }),
            }}
          />
        )}
      </Container>
      <br></br>
    </>
  );
}
export default LotteryDisplay;
