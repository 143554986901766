import * as React from 'react';
import {
  Col,
  Row,
} from "reactstrap";
import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import { useStyles } from './CustomComponent/reuseableFn';
import { TextField } from '@material-ui/core';
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ProfitCalculator() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [PurchasePrice, setPurchasePrice] = React.useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`
          label: classes.label, // class name, e.g. `classes-nesting-label-x`
        }}
        onClick={handleClickOpen}
      >
        Profit Calculator
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <Row className="align-items-center">
            <Col md={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Purchase Price:
              </Typography>
            </Col>
            <Col md={6}>
              <TextField
                value={PurchasePrice}
                variant="standard"
                onChange={a => setPurchasePrice(a.target.value)}
              />

            </Col>
          </Row>&nbsp;&nbsp;&nbsp;&nbsp;
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">10% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.10).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">15% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.15).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">20% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.20).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">25% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.25).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">30% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.30).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">35% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.35).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">40% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.40).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">45% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.45).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">50% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.50).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">55% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.55).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">60% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.60).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">65% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.65).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">70% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.70).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">75% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.75).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">80% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.80).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">85% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.85).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">90% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.90).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">95% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 0.95).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">100% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">105% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.05).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">110% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.10).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">115% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.15).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">120% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.20).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">125% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.25).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">130% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.30).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">135% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.35).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">140% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.40).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">145% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.45).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">150% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.50).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">155% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.55).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="h3 mb-0">160% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.60).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
            <Col md={6}>
              <h5 className="h3 mb-0">165% = <NumberFormat value={parseFloat(PurchasePrice) + parseFloat((PurchasePrice * 1.65).toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
