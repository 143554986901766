import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { DailyReport, GasReport, } from "../CustomComponent/BackendDataConfig";
import { FormatDateMMDDYYY, FormatDateYYYY, FormatDateMM, FormatDateDD } from "../CustomComponent/reuseableFn";

var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
//gas regular add/update
export function RegualrGasAddUpdate(RowData, DataLocation) {
    firebase.database().ref(GasReport + '/' + username + "/" + DataLocation + "/" + FormatDateYYYY(RowData.RegularDate) + "/"
        + FormatDateMM(RowData.RegularDate) + "/" + FormatDateDD(RowData.RegularDate)).set({
            RegularDate: FormatDateMMDDYYY(RowData.RegularDate),
            RegularInventory: RowData.RegularInventory !== undefined ? RowData.RegularInventory : 0,
            RegularGallon: RowData.RegularGallon !== undefined ? RowData.RegularGallon : 0,
            RegularPrice: RowData.RegularPrice !== undefined ? RowData.RegularPrice : 0,
            RegularTotalSale: RowData.RegularGallon !== undefined ? (RowData.RegularGallon * RowData.RegularPrice) : 0,
            RegularReading: RowData.RegularReading !== undefined ? RowData.RegularReading : 0,
        })
    firebase.database().ref(DailyReport + '/' + username + "/" + FormatDateYYYY(RowData.RegularDate) + "/"
        + FormatDateMM(RowData.RegularDate) + "/" + FormatDateDD(RowData.RegularDate)).update({
            Date: FormatDateMMDDYYY(RowData.RegularDate),
            GasRegularGallon: RowData.RegularGallon !== undefined ? RowData.RegularGallon : 0,
            GasRegularPrice: RowData.RegularPrice !== undefined ? RowData.RegularPrice : 0,
            GasRegularSale: RowData.RegularGallon !== undefined ? (RowData.RegularGallon * RowData.RegularPrice) : 0,
        })
}
export default RegualrGasAddUpdate;

//gas premium add/update
export function PremiumGasAddUpdate(RowData, DataLocation) {
    firebase.database().ref(GasReport + '/' + username + "/" + DataLocation + "/" + FormatDateYYYY(RowData.PremiumDate) + "/"
        + FormatDateMM(RowData.PremiumDate) + "/" + FormatDateDD(RowData.PremiumDate)).set({
            PremiumDate: FormatDateMMDDYYY(RowData.PremiumDate),
            PremiumInventory: RowData.PremiumInventory !== undefined ? RowData.PremiumInventory : 0,
            PremiumGallon: RowData.PremiumGallon !== undefined ? RowData.PremiumGallon : 0,
            PremiumPrice: RowData.PremiumPrice !== undefined ? RowData.PremiumPrice : 0,
            PremiumTotalSale: RowData.PremiumGallon !== undefined ? (RowData.PremiumGallon * RowData.PremiumPrice) : 0,
            PremiumReading: RowData.PremiumReading !== undefined ? RowData.PremiumReading : 0,
        })
    firebase.database().ref(DailyReport + '/' + username + "/" + FormatDateYYYY(RowData.PremiumDate) + "/"
        + FormatDateMM(RowData.PremiumDate) + "/" + FormatDateDD(RowData.PremiumDate)).update({
            Date: FormatDateMMDDYYY(RowData.PremiumDate),
            GasPremiumGallon: RowData.PremiumGallon !== undefined ? RowData.PremiumGallon : 0,
            GasPremiumPrice: RowData.PremiumPrice !== undefined ? RowData.PremiumPrice : 0,
            GasPremiumSale: RowData.PremiumGallon !== undefined ? (RowData.PremiumGallon * RowData.PremiumPrice) : 0,
        })
}
//gas diesel add/update
export function DieselGasAddUpdate(RowData, DataLocation) {
    firebase.database().ref(GasReport + '/' + username + "/" + DataLocation + "/" + FormatDateYYYY(RowData.DieselDate) + "/"
        + FormatDateMM(RowData.DieselDate) + "/" + FormatDateDD(RowData.DieselDate)).set({
            DieselDate: FormatDateMMDDYYY(RowData.DieselDate),
            DieselInventory: RowData.DieselInventory !== undefined ? RowData.DieselInventory : 0,
            DieselGallon: RowData.DieselGallon !== undefined ? RowData.DieselGallon : 0,
            DieselPrice: RowData.DieselPrice !== undefined ? RowData.DieselPrice : 0,
            DieselTotalSale: RowData.DieselGallon !== undefined ? (RowData.DieselGallon * RowData.DieselPrice) : 0,
            DieselReading: RowData.DieselReading !== undefined ? RowData.DieselReading : 0,
        })
    firebase.database().ref(DailyReport + '/' + username + "/" + FormatDateYYYY(RowData.DieselDate) + "/"
        + FormatDateMM(RowData.DieselDate) + "/" + FormatDateDD(RowData.DieselDate)).update({
            Date: FormatDateMMDDYYY(RowData.DieselDate),
            GasDieselGallon: RowData.DieselGallon !== undefined ? RowData.DieselGallon : 0,
            GasDieselPrice: RowData.DieselPrice !== undefined ? RowData.DieselPrice : 0,
            GasDieselSale: RowData.DieselGallon !== undefined ? (RowData.DieselGallon * RowData.DieselPrice) : 0,
        })
}

//gas delivery add/update
