/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';
import { Col, Row, Form, Card, CardBody, CardHeader } from 'reactstrap';
// core components
import firebase from 'firebase/app';
import 'firebase/auth';
import { DailyReport, LotteryReport } from '../CustomComponent/BackendDataConfig';
import 'firebase/firestore';
import 'firebase/database';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useStyles } from '../CustomComponent/reuseableFn';

export function NewLottery(GameInBox) {
  const [NewLotteryHistory, setNewLotteryHistory] = React.useState();
  const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
  const [CheckIfReportExist, setCheckIfReportExist] = React.useState(false);
  const [NewLotteryDate, setNewLotteryDate] = React.useState(new Date());
  const [ScractOffCash, setScractOffCash] = React.useState(0);
  const [LottoSale, setLottoSale] = React.useState(0);
  const [LottoCash, setLottoCash] = React.useState(0);

  const classes = useStyles();
  let date = NewLotteryDate.getDate();
  let month = NewLotteryDate.getMonth() + 1;
  let year = NewLotteryDate.getFullYear();
  var username =
    localStorage.getItem('email') === null
      ? 'kingswayanish'
      : localStorage
          .getItem('email')
          .split('@')[0]
          .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

  /*const [GameInBoxSold] = useState([
        { title: "Box No", field: "BoxNo", type: "number", editable: 'never' },
        { title: "Game No", field: "GameNo", type: "number", editable: 'never' },
        { title: "Game Price", field: "GamePrice", type: "currency", editable: 'never' },
        { title: "Start", field: "Start", type: "number" },
        { title: "End", field: "End", type: "number", },
        {
            title: "Quantity", field: "QuantitySold", type: "number", editable: 'never',
            render: rowData => {
                var Start = rowData.Start === undefined ? 0 : rowData.Start
                var End = rowData.End === undefined ? 0 : rowData.End
                var calculation = End - Start;
                return calculation
            },
        },
        {
            title: "Total Amount", field: "TotalAmount", type: "currency", editable: 'never',
            render: rowData => {
                var GamePrice = rowData.GamePrice === undefined ? 0 : rowData.GamePrice
                var Start = rowData.Start === undefined ? 0 : rowData.Start
                var End = rowData.End === undefined ? 0 : rowData.End
                var calculation = (End - Start) * GamePrice;
                return calculation
            },
        },
    ]);*/
  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 =
      localStorage.getItem('email') === null
        ? 'kingswayanish'
        : localStorage
            .getItem('email')
            .split('@')[0]
            .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    let date1 = NewLotteryDate.getDate();
    let month1 = NewLotteryDate.getMonth() + 1;
    let year1 = NewLotteryDate.getFullYear();
    const dbRefOrder = firebase
      .database()
      .ref(
        LotteryReport + '/' + username1 + '/TicketSoldDetail/' + year1 + '/' + month1 + '/' + date1
      );
    dbRefOrder
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          setCheckIfReportExist(true);
        } else {
          setCheckIfReportExist(false);
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    if (GameInBox.GameInBox !== undefined) {
      let formatData = [];
      GameInBox.GameInBox.map((a) => {
        formatData.push({
          Date: month1 + '/' + date1 + '/' + year1,
          id: a.id,
          BoxNo: a.BoxNo,
          GameName: a.GameName,
          GameNo: a.GameNo,
          GamePrice: a.GamePrice,
          PackSize: a.PackSize,
          Start: null,
          End: null,
          QuantitySold: 0,
          TotalAmount: 0,
        });
        return null;
      });
      setNewLotteryHistory(formatData);
    }
  }, [CheckIfReportExist, GameInBox.GameInBox, NewLotteryDate]); // placing this will render on state change

  console.log(GameInBox.GameInBox);
  console.log(NewLotteryHistory);

  const handleOrderForm = (event) => {
    event.preventDefault();
    NewLotteryHistory.map((a) => {
      firebase
        .database()
        .ref(
          LotteryReport + '/' + username + '/TicketSoldDetail/' + year + '/' + month + '/' + date
        )
        .push({
          Date: month + '/' + date + '/' + year,
          BoxId: a.id,
          BoxNo: a.BoxNo,
          GameName: a.GameName,
          GameNo: a.GameNo,
          GamePrice: a.GamePrice,
          PackSize: a.PackSize,
          StartOn: a.Start,
          EndOn: a.End,
          QuantitySold: a.QuantitySold,
          TotalAmount: a.TotalAmount,
        });
      return null;
    });
    // lottery summary data
    firebase
      .database()
      .ref(LotteryReport + '/' + username + '/TicketSoldSummary/' + year + '/' + month + '/' + date)
      .set({
        Date: month + '/' + date + '/' + year,
        ScractOffQuantity: NewLotteryHistory.reduce((a, b) => (a = a + Number(b.QuantitySold)), 0),
        ScractOffAmount: NewLotteryHistory.reduce((a, b) => (a = a + Number(b.TotalAmount)), 0),
        ScractOffCash: ScractOffCash,
        LottoSale: LottoSale,
        LottoCash: LottoCash,
        LotteryLottoSale:
          LottoSale + NewLotteryHistory.reduce((a, b) => (a = a + Number(b.TotalAmount)), 0),
        LotteryLottoCash: LottoCash + ScractOffCash,
      });
    // add data to report
    firebase
      .database()
      .ref(DailyReport + '/' + username + '/' + year + '/' + month + '/' + date)
      .update({
        Date: month + '/' + date + '/' + year,
        LotteryCash: ScractOffCash,
        LotterySale: NewLotteryHistory.reduce((a, b) => (a = a + Number(b.TotalAmount)), 0),
        LotteryDiff:
          NewLotteryHistory.reduce((a, b) => (a = a + Number(b.TotalAmount)), 0) - ScractOffCash,
        LottoCash: LottoCash,
        LottoSale: LottoSale,
        LottoDiff: LottoSale - LottoCash,
        LotteryLottoSale:
          LottoSale + NewLotteryHistory.reduce((a, b) => (a = a + Number(b.TotalAmount)), 0),
        LotteryLottoCash: LottoCash + ScractOffCash,
      });
    setShowUpdateSuccessMessage(true);
    setTimeout(function () {
      setShowUpdateSuccessMessage(false);
      setCheckIfReportExist(true);
      setLottoSale(0);
      setLottoCash(0);
      setScractOffCash(0);
    }, 2000); //wait 5 seconds
  };
  console.log('new report exist = ' + CheckIfReportExist);
  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">Select Date to Create New Lottery Report</h3>
            </div>
            {ShowUpdateSuccessMessage === true ? (
              <>
                <Alert severity="success">
                  Lottery Updated Successfully for {month + '/' + date + '/' + year}
                </Alert>
              </>
            ) : null}
            <div className="col text-right">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Lottery Date"
                  openTo="year"
                  views={['year', 'month', 'day']}
                  value={NewLotteryDate}
                  onChange={(newValue) => {
                    setNewLotteryDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </Row>
        </CardHeader>
      </Card>
      {CheckIfReportExist === false ? (
        <>
          {ShowUpdateSuccessMessage === true ? (
            <>
              <Alert severity="success">
                NewLottery Report Addedd Successfully for {month + '/' + date + '/' + year}
              </Alert>
            </>
          ) : null}
          <Form role="form" onSubmit={handleOrderForm}>
            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Date: {month + '/' + date + '/' + year}</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      classes={{
                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                      }}
                      type="submit">
                      Submit Report
                    </Button>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Sold / Cash Summary</h3>
                  </div>
                </Row>
                <br></br>
                <Row>
                  <Col sm={2}>
                    <CurrencyTextField
                      label="Lotto Sold"
                      value={LottoSale}
                      variant="standard"
                      currencySymbol="$"
                      onChange={(event, value) => setLottoSale(value)}
                    />
                  </Col>
                  <Col sm={2}>
                    <CurrencyTextField
                      label="Lotto Cash"
                      value={LottoCash}
                      variant="standard"
                      currencySymbol="$"
                      onChange={(event, value) => setLottoCash(value)}
                    />
                  </Col>
                  <Col sm={2}>
                    <CurrencyTextField
                      label="Scratch-Off Cash"
                      value={ScractOffCash}
                      variant="standard"
                      currencySymbol="$"
                      onChange={(event, value) => setScractOffCash(value)}
                    />
                  </Col>
                </Row>
                <br></br>
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Enter Scracth-Off Details Below</h3>
                  </div>
                </Row>
              </CardBody>
              {NewLotteryHistory !== undefined
                ? NewLotteryHistory.map((a) => {
                    return (
                      <>
                        <CardBody>
                          <Row>
                            <Col sm={2}>
                              <TextField
                                disabled
                                id="filled-disabled"
                                label="Box No"
                                value={a.BoxNo}
                                variant="standard"
                                //onChange={a => setPurchaseName(a.target.value)}
                              />
                            </Col>
                            <Col sm={2}>
                              <TextField
                                disabled
                                id="filled-disabled"
                                label="Game No"
                                value={a.GameNo}
                                variant="standard"
                                //onChange={a => setPurchaseName(a.target.value)}
                              />
                            </Col>
                            <Col sm={2}>
                              <CurrencyTextField
                                disabled
                                id="filled-disabled"
                                label="Game Price"
                                value={a.GamePrice}
                                variant="standard"
                                currencySymbol="$"
                                //onChange={(event, value) => setPurchaseAmount(value)}
                              />
                            </Col>
                            <Col sm={2}>
                              <TextField
                                type="number"
                                label="Start"
                                value={a.Start}
                                variant="standard"
                                onChange={(event, value) => {
                                  NewLotteryHistory.map((b) => {
                                    if (b.id === a.id) {
                                      b.Start = event.target.value;
                                    }
                                    return null;
                                  });
                                }}
                              />
                            </Col>
                            <Col sm={2}>
                              <TextField
                                type="number"
                                label="End"
                                value={a.End}
                                variant="standard"
                                onChange={(event, value) => {
                                  NewLotteryHistory.map((b) => {
                                    if (b.id === a.id) {
                                      b.End = event.target.value;
                                      b.QuantitySold = b.End - b.Start;
                                      b.TotalAmount = (b.End - b.Start) * b.GamePrice;
                                    }
                                    return null;
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </>
                    );
                  })
                : null}
            </Card>
          </Form>
        </>
      ) : (
        <>
          <Alert severity="success">
            Lottery report for date {month + '/' + date + '/' + year + ' '} alredy exists. If you
            would like to edit the lottery report please visit "Update Lottery" section.
          </Alert>
        </>
      )}
    </>
  );
}
export default NewLottery;
