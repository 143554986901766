/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
    Col,
    Row,
    Label,
    Card,
    CardHeader,
    CardBody,
} from "reactstrap";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import { LotteryReport } from "../CustomComponent/BackendDataConfig";
import { GameHistoryByDay } from "../CustomComponent/TableColumn";
import TableHeaderColor from "../CustomComponent/Colors";
import { tableIcons } from "../CustomComponent/reuseableFn";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import DialogContent from "@material-ui/core/DialogContent";
export function ViewReport(DataRow) {
    var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    var selectedRowData = DataRow.DataRow;
    //console.log(selectedRowData)
    return (
        <>
            <DialogContent>
                <Row form>
                    <Col md={12}>
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Summary Report Date #{selectedRowData.Date}</h3>
                                    </div>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Tax / Non-tax: </b>
                                            <NumberFormat
                                                value={selectedRowData.TotalGrocery}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Gas: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasTotal}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lottery / Lotto: </b>
                                            <NumberFormat
                                                value={selectedRowData.LotteryLottoSale}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Sale: </b>
                                            <NumberFormat
                                                value={selectedRowData.TotalSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Expense / Purchase: </b>
                                            <NumberFormat
                                                value={selectedRowData.ExpensePurchase}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Over / Short: </b>
                                            <NumberFormat
                                                value={selectedRowData.OverShortShift}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <div className="col">
                                    <h3 className="mb-0">Grocery</h3>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Tax: </b>
                                            <NumberFormat
                                                value={selectedRowData.Tax}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Non-Tax: </b>
                                            <NumberFormat
                                                value={selectedRowData.NonTax}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>

                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Beer: </b>
                                            <NumberFormat
                                                value={selectedRowData.Beer}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Other Tax: </b>
                                            <NumberFormat
                                                value={selectedRowData.OtherTax}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Other Non-Tax: </b>
                                            <NumberFormat
                                                value={selectedRowData.OtherNonTax}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Cigratte: </b>
                                            <NumberFormat
                                                value={selectedRowData.Cigratte}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Deli: </b>
                                            <NumberFormat
                                                value={selectedRowData.Deli}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Other Food: </b>
                                            <NumberFormat
                                                value={selectedRowData.OtherFood}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Tax Sale: </b>
                                            <NumberFormat
                                                value={selectedRowData.TotalTaxSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Tax Collected: </b>
                                            <NumberFormat
                                                value={selectedRowData.TotalTaxCollected}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Non - Tax Sale: </b>
                                            <NumberFormat
                                                value={selectedRowData.TotalNonTaxSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <div className="col">
                                    <h3 className="mb-0">Credit Card / Cash</h3>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Credit / Debit Card: </b>
                                            <NumberFormat
                                                value={selectedRowData.CreditDebitCard}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Check Cashing: </b>
                                            <NumberFormat
                                                value={selectedRowData.CheckCashing}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Total Card / Cash: </b>
                                            <NumberFormat
                                                value={selectedRowData.CardCash}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Cash: </b>
                                            <NumberFormat
                                                value={selectedRowData.Cash}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Money-Order: </b>
                                            <NumberFormat
                                                value={selectedRowData.MoneyOrder}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Western Union: </b>
                                            <NumberFormat
                                                value={selectedRowData.WesternUnion}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Credit / Debit Card: </b>
                                            <NumberFormat
                                                value={selectedRowData.CreditDebitCard}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <div className="col">
                                    <h3 className="mb-0">Lottery</h3>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Lottery Sales: </b>
                                            <NumberFormat
                                                value={selectedRowData.LotterySale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Lotto Sales: </b>
                                            <NumberFormat
                                                value={selectedRowData.LottoSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Lottery Cash: </b>
                                            <NumberFormat
                                                value={selectedRowData.LotteryCash}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Lotto Cash: </b>
                                            <NumberFormat
                                                value={selectedRowData.LottoCash}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lottery Diff: </b>
                                            <NumberFormat
                                                value={selectedRowData.LotteryDiff}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                        <br></br>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Lotto Diff: </b>
                                            <NumberFormat
                                                value={selectedRowData.LottoDiff}
                                                displayType={"text"}
                                                allowNegative={true}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <div className="col">
                                    <h3 className="mb-0">Gas</h3>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Regular Gallons: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasRegularGallon}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Regular Price: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasRegularPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Regular Sale: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasRegularSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Premium Gallons: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasPremiumGallon}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Premium Price: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasPremiumPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Premium Sale: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasPremiumSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Diesel Gallons: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasDieselGallon}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "" }}>
                                            <b>Diesel Price: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasDieselPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label><br></br>
                                        <Label style={{ backgroundColor: "#FFFD77" }}>
                                            <b>Diesel Sale: </b>
                                            <NumberFormat
                                                value={selectedRowData.GasDieselSale}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </Label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row form>
                    <Col md={12}>
                        <MaterialTable
                            icons={tableIcons}
                            title={"Summary Scratch-Off Lotterys"}
                            columns={GameHistoryByDay.map((c) => ({ ...c, tableData: undefined }))}
                            data={() =>
                                new Promise((resolve) => {
                                    let newDate2 = new Date(selectedRowData.Date);
                                    let date2 = newDate2.getDate();
                                    let month2 = newDate2.getMonth() + 1;
                                    let year2 = newDate2.getFullYear();
                                    const gdbRefselectedRowDataSummary = firebase.database().ref(LotteryReport + "/" + username +
                                        "/TicketSoldDetail/" + year2 + "/" + month2 + "/" + date2);
                                    //console.log(selectedRowData.Date)
                                    // will fetch summary report of lottery
                                    gdbRefselectedRowDataSummary.get().then((snapshot) => {
                                        let users = [];
                                        if (snapshot.exists()) {
                                            snapshot.forEach((snap) => {
                                                users.push({ id: snap.key, ...snap.val() });
                                            });
                                            //console.log(users)
                                            resolve({
                                                data: users,
                                            })
                                        } else {
                                            resolve({
                                                data: "Data does not exist",
                                            })
                                            console.log("No data available");
                                        }
                                    }).catch((error) => {
                                        console.error(error);
                                    });
                                })
                            }
                            options={{
                                //exportButton: true,
                                search: false,
                                paging: false,
                                headerStyle: {
                                    backgroundColor: TableHeaderColor,
                                    color: "#000000",
                                    textColor: "#000000",
                                },
                            }}
                        />
                    </Col>
                </Row>{" "}
            </DialogContent>
        </>
    );
}
export default ViewReport;
