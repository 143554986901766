/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import React from 'react';
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Form,
  Nav,
  Container,
} from 'reactstrap';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import OptionGroupUnstyled from '@mui/base/OptionGroupUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import handleLogout from 'views/authUI/Logout';
import { CustomTextBlack } from 'views/adminUI/CustomComponent/reuseableFn';
import 'firebase/firestore';
import 'firebase/database';
import ProfitCalculator from 'views/adminUI/ProfitCalculator';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width:150px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 200px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
);

const StyledGroupRoot = styled('li')`
  list-style: none;
`;

const StyledGroupHeader = styled('span')`
  display: block;
  padding: 15px 0 5px 10px;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`;

const StyledGroupOptions = styled('ul')`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
};

const CustomOptionGroup = React.forwardRef(function CustomOptionGroup(props, ref) {
  const components = {
    Root: StyledGroupRoot,
    Label: StyledGroupHeader,
    List: StyledGroupOptions,
    ...props.components,
  };

  return <OptionGroupUnstyled {...props} ref={ref} components={components} />;
});

CustomOptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroupUnstyled.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Label: PropTypes.elementType,
    List: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
};
function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  var username =
    localStorage.getItem('email') === null
      ? 'kingswayanish'
      : localStorage
          .getItem('email')
          .split('@')[0]
          .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
  //year, month, date selected to filter the data
  var SelectedMonth = Number(localStorage.getItem('Month'));
  var SelectedYear = Number(localStorage.getItem('Year'));
  return (
    <>
      <Navbar
        className={classnames('navbar-top navbar-expand border-bottom')}
        style={{ backgroundColor: '#F2EDD7FF' }}>
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                'navbar-search form-inline mr-sm-3',
                { 'navbar-search-light': theme === 'dark' },
                { 'navbar-search-dark': theme === 'light' }
              )}>
              <CustomSelect
                defaultValue={SelectedMonth}
                value={SelectedYear}
                onChange={(a) => {
                  console.log(a);
                  localStorage.setItem('Year', a);
                  window.location.reload();
                }}>
                <CustomOptionGroup label="Year">
                  <StyledOption value={2018}>2018</StyledOption>
                  <StyledOption value={2019}>2019</StyledOption>
                  <StyledOption value={2020}>2020</StyledOption>
                  <StyledOption value={2021}>2021</StyledOption>
                  <StyledOption value={2022}>2022</StyledOption>
                  <StyledOption value={2023}>2023</StyledOption>
                </CustomOptionGroup>
              </CustomSelect>
              <CustomSelect
                defaultValue={SelectedMonth}
                value={SelectedMonth}
                onChange={(a) => {
                  localStorage.setItem('Month', a);
                  window.location.reload();
                }}>
                <CustomOptionGroup label="Month">
                  <StyledOption value={1}>January</StyledOption>
                  <StyledOption value={2}>February</StyledOption>
                  <StyledOption value={3}>March</StyledOption>
                  <StyledOption value={4}>April</StyledOption>
                  <StyledOption value={5}>May</StyledOption>
                  <StyledOption value={6}>June</StyledOption>
                  <StyledOption value={7}>July</StyledOption>
                  <StyledOption value={8}>August</StyledOption>
                  <StyledOption value={9}>September</StyledOption>
                  <StyledOption value={10}>October</StyledOption>
                  <StyledOption value={11}>November</StyledOption>
                  <StyledOption value={12}>December</StyledOption>
                </CustomOptionGroup>
              </CustomSelect>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div className={classnames('pr-3 sidenav-toggler')} onClick={toggleSidenav}>
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-xl-none">
                <CustomSelect
                  defaultValue={SelectedMonth}
                  value={SelectedYear}
                  onChange={(a) => {
                    console.log(a);
                    localStorage.setItem('Year', a);
                    window.location.reload();
                  }}>
                  <CustomOptionGroup label="Year">
                    <StyledOption value={2018}>2018</StyledOption>
                    <StyledOption value={2019}>2019</StyledOption>
                    <StyledOption value={2020}>2020</StyledOption>
                    <StyledOption value={2021}>2021</StyledOption>
                    <StyledOption value={2022}>2022</StyledOption>
                    <StyledOption value={2023}>2023</StyledOption>
                  </CustomOptionGroup>
                </CustomSelect>
                <CustomSelect
                  defaultValue={SelectedMonth}
                  value={SelectedMonth}
                  onChange={(a) => {
                    localStorage.setItem('Month', a);
                    window.location.reload();
                  }}>
                  <CustomOptionGroup label="Month">
                    <StyledOption value={1}>January</StyledOption>
                    <StyledOption value={2}>February</StyledOption>
                    <StyledOption value={3}>March</StyledOption>
                    <StyledOption value={4}>April</StyledOption>
                    <StyledOption value={5}>May</StyledOption>
                    <StyledOption value={6}>June</StyledOption>
                    <StyledOption value={7}>July</StyledOption>
                    <StyledOption value={8}>August</StyledOption>
                    <StyledOption value={9}>September</StyledOption>
                    <StyledOption value={10}>October</StyledOption>
                    <StyledOption value={11}>November</StyledOption>
                    <StyledOption value={12}>December</StyledOption>
                  </CustomOptionGroup>
                </CustomSelect>
              </NavItem>
              <ProfitCalculator />

              {/* 
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" tag="a">
                  <i className="ni ni-calendar-grid-58" style={{ color: CustomTextBlack }} /> <span style={{ color: CustomTextBlack }}>Economic Calender</span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  XX
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="fas fa-chart-area" style={{ color: CustomTextBlack }} /> <span style={{ color: CustomTextBlack }}>Stock Rating</span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  XX
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="fas fa-calendar-day" style={{ color: CustomTextBlack }} /> <span style={{ color: CustomTextBlack }}>Events</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-dark" right>
                  XX
                </DropdownMenu>
              </UncontrolledDropdown>*/}
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span
                      className="avatar avatar-sm rounded-circle"
                      style={{ color: CustomTextBlack }}>
                      {/* <img
                        alt="..."
                        src={require("assets/img/theme/team-4.jpg").default}
                     />*/}
                      A
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span
                        className="mb-0 text-sm font-weight-bold"
                        style={{ color: CustomTextBlack }}>
                        {username}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  {/*  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0" style={{ color: CustomTextBlack }}>Welcome!</h6>
                  </DropdownItem>*/}
                  <DropdownItem>
                    <NavItem to="/admin/profile" tag={Link}>
                      <i className="fas fa-user" style={{ color: CustomTextBlack }}></i>
                      <span style={{ color: CustomTextBlack }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Profile
                      </span>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem to="/admin/settings" tag={Link}>
                      <i className="fas fa-cog" style={{ color: CustomTextBlack }}></i>
                      <span style={{ color: CustomTextBlack }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Settings
                      </span>
                    </NavItem>
                  </DropdownItem>
                  {/*<DropdownItem>
                    <NavItem to="/admin/support" tag={Link}>
                      <i className="fas fa-ticket-alt" style={{ color: CustomTextBlack }}></i>
                      <span style={{ color: CustomTextBlack }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Support</span>
                    </NavItem>
                  </DropdownItem>*/}
                  <DropdownItem>
                    <NavItem to="/admin/subscription" tag={Link}>
                      <i className="fas fa-file-contract" style={{ color: CustomTextBlack }}></i>
                      <span style={{ color: CustomTextBlack }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subscription
                      </span>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <NavItem to="/" onClick={handleLogout} tag={Link}>
                      <i className="fas fa-sign-out-alt" style={{ color: CustomTextBlack }}></i>
                      <span onClick={handleLogout} style={{ color: CustomTextBlack }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Logout
                      </span>
                    </NavItem>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
