export const firebaseConfig = {
  apiKey: "AIzaSyDphjCAOCxHymm1K9Fl5vRhYI2azw7Gqk4",
  authDomain: "retail-accounting-4d33a.firebaseapp.com",
  projectId: "retail-accounting-4d33a",
  storageBucket: "retail-accounting-4d33a.appspot.com",
  messagingSenderId: "284111904505",
  appId: "1:284111904505:web:0fc53e33d13f85ff558c28",
  measurementId: "G-HW9ZQVXVSY",
  databaseURL: "https://retail-accounting-4d33a-default-rtdb.firebaseio.com/",
};
export default firebaseConfig;
