/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
  Col,
  Row,
  Form,
  CardHeader,
  CardBody,
  Card,
  Container,
} from "reactstrap";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import {
  ExpenseReport,
} from "./CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import { tableIcons, useStyles } from "./CustomComponent/reuseableFn";
import Button from "@material-ui/core/Button";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import UpdateExpense from "./SubExpense/UpdateExpense";
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SummaryDailyExpense } from "./CustomComponent/TableColumn";
import TableHeaderColor from "./CustomComponent/Colors";
import Alert from '@mui/material/Alert';

export function Expense() {
  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const [ExpenseHistory, setExpenseHistory] = React.useState([])
  const [ExpenseExistingEdit, setExpenseExistingEdit] = React.useState(false);
  const [ViewSummaryExpense, setViewSummaryExpense] = React.useState(true);
  const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
  // variable to save Expense data
  //expense
  const [ExpenseDate, setExpenseDate] = React.useState(new Date());
  const [ExpenseName, setExpenseName] = React.useState("");
  const [ExpenseAmount, setExpenseAmount] = React.useState(0);
  const [ExpensePaymentType, setExpensePaymentType] = React.useState("Cash");
  const [ExpenseNote, setExpenseNote] = React.useState("");
  const [MTDTotalExpense, setMTDTotalExpense] = React.useState(0);

  //year, month, date selected to filter the data
  let date = ExpenseDate.getDate();
  let month = ExpenseDate.getMonth() + 1;
  let year = ExpenseDate.getFullYear();

  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');
  const classes = useStyles();

  // to filter the data before fetch
  var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
  // column to show on invoice table and create row radomly


  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const dbRefOrder = firebase.database().ref(ExpenseReport + '/' + username1 + "/" + SelectedYear + "/" + SelectedMonth);
    dbRefOrder.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push(snap.val());
        });
        //console.log(users)
        var formatList = []
        users.map(a => {
          //console.log(Object.values(a))
          Object.values(a).map(b => {
            formatList.push(b)
            return null
          })
          return null
        })
        setExpenseHistory(formatList)
        setMTDTotalExpense(formatList.reduce((a, b) => a = a + Number(b.Amount), 0))
      } else {
        setExpenseHistory(null);
        setMTDTotalExpense(0)
        console.log("No data available");
      }
    })
      .catch((error) => {
        console.error(error);
      });
    /*firebase.database().ref(DailyReport + '/' + username1 + "/" + year1 + "/" + month1 + "/" + date1).update({
      Date: FormatDateMMDDYYY(ExpenseDate),
      DailyExpense: ExpenseHistory.filter(a => a.Date === FormatDateMMDDYYY(ExpenseDate)).reduce((a, b) => a = a + Number(b.Amount), 0),
    })*/
  }, [ShowUpdateSuccessMessage, SelectedMonth, SelectedYear, ExpenseDate, ExpenseHistory]); // placing this will render on state change
  //console.log(ExpenseHistory)
  //console.log(FormatDateMMDDYYY(ExpenseDate))
  //send data to database and create Expense for customers
  const handleOrderForm = (event) => {
    event.preventDefault();
    // generate unique Expense number
    let NewDate = month + "/" + date + "/" + year
    //console.log(ExpenseHistory)
    firebase.database().ref(ExpenseReport + "/" + username + "/" + year + "/" + month + "/" + date).push({
      Date: NewDate,
      Name: ExpenseName,
      Amount: ExpenseAmount,
      PaymentType: ExpensePaymentType,
      Note: ExpenseNote
    });
    setShowUpdateSuccessMessage(true)
    setTimeout(function () {
      setShowUpdateSuccessMessage(false)
    }, 2000);//wait 5 seconds
  };

  const handleEditExistingExpense = () => {
    setViewSummaryExpense(false);
    setExpenseExistingEdit(true)
  };
  const handleSummaryExpense = () => {
    setViewSummaryExpense(true);
    setExpenseExistingEdit(false)
  };
  return (
    <>
      <br /><br /><br /><br></br>
      <Container className="mt--6" fluid>
        {showNewOrderForm === true ? (
          <>
            <CardHeader>
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Daily Expense Details</h3>
                </div>
                <div className="col">
                  {
                    ShowUpdateSuccessMessage === true ?
                      <>
                        <Alert severity="success">
                          Expense Added Successfully for {month + "/" + date + "/" + year}
                        </Alert>
                      </> : null
                  }
                </div>
                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => {
                      setShowNewOrderForm(false);
                    }}
                  >
                    Go Back
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <br></br>
            <Form role="form" onSubmit={handleOrderForm}>
              <Row form>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Summary</h3>
                        </div>
                        <div className="col text-right">
                          <Button
                            classes={{
                              root: classes.root, // class name, e.g. `classes-nesting-root-x`
                              label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                            type="submit"
                          >
                            Submit Expense
                          </Button>
                        </div>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Row>
                        <Col sm={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disableFuture
                              label="Purchase Date"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              value={ExpenseDate}
                              onChange={(newValue) => {
                                setExpenseDate(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Col>
                        <Col sm={2}>
                          <TextField
                            label="Company Name"
                            value={ExpenseName}
                            variant="standard"
                            onChange={a => setExpenseName(a.target.value)}
                          />
                        </Col>
                        <Col sm={2}>
                          <CurrencyTextField
                            label="Amount"
                            value={ExpenseAmount}
                            variant="standard"
                            currencySymbol="$"
                            onChange={(event, value) => setExpenseAmount(value)}
                          />
                        </Col>
                        <Col sm={2}>
                          <FormControl required sx={{ width: 150 }}>
                            <InputLabel id="demo-simple-select-required-label">Payment Type</InputLabel>
                            <Select
                              type="select"
                              defaultValue="Cash"
                              value={ExpensePaymentType}
                              label="Payment Type"
                              onChange={a => setExpensePaymentType(a.target.value)}
                            >
                              <MenuItem value="Cash">Cash</MenuItem><br></br>
                              <MenuItem value="Check">Check</MenuItem><br></br>
                              <MenuItem value="Money-Order">Money-Order</MenuItem><br></br>
                              <MenuItem value="Card">Card</MenuItem><br></br>
                            </Select>
                          </FormControl>
                        </Col>
                        <Col sm={2}>
                          <TextField
                            label="Note"
                            value={ExpenseNote}
                            variant="standard"
                            onChange={a => setExpenseNote(a.target.value)}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            {/* header for functionality like summary, update Expense, create new Expense */}
            <CardHeader className="border-0">
              <Row className="align-items-center">
                {ViewSummaryExpense === true ?
                  <Row>
                    <div className="col">
                      <h5 className="h3 mb-0">Total Expense MTD: <NumberFormat value={MTDTotalExpense.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                    </div>
                  </Row>
                  : null}

                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={handleSummaryExpense}
                  >
                    Expense Summary
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={handleEditExistingExpense}
                  >
                    Update Expense
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => setShowNewOrderForm(true)}
                  >
                    New Expense
                  </Button>
                </div>
              </Row>

            </CardHeader><br></br>

            {/*below code will display the table */}
            {ExpenseHistory !== null ? (
              <>
                {ViewSummaryExpense === true ?
                  <>
                    <MaterialTable
                      icons={tableIcons}
                      title="Summary Expenses"
                      columns={SummaryDailyExpense.map((c) => ({ ...c, tableData: undefined }))}
                      data={ExpenseHistory}
                      onChangeRowsPerPage={10}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                  </> : null}
                {ExpenseExistingEdit === true ?
                  <>
                    <UpdateExpense />
                  </> : null}
              </>
            ) : <>
              <Alert severity="info">
                There is NO Data for Selected Month & Year
              </Alert>
            </>}
          </>
        )}
      </Container>
      <br></br>
    </>
  );
}
export default Expense;
