import React, { forwardRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { WarrentyHistory, } from "./BackendDataConfig";
import NumberFormat from "react-number-format";
import moment from 'moment';

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
export const CustomStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        background: "linear-gradient(45deg, #5AB4FB 30%, #289FFC 90%)",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(4, 116, 204, .3)",
    },
    label: {
        textTransform: "capitalize",
    },
}));
export const CustomTextBlack = "#000000"
export const CustomTextBlue = "#001FFE"
export function FormatDateMMDDYYY(dateReceived) {
    let formatDate = new Date(dateReceived);
    let date = formatDate.getDate();
    let month = formatDate.getMonth() + 1;
    let year = formatDate.getFullYear();
    return month + "/" + date + "/" + year
}
export default FormatDateMMDDYYY;
export function FormatDateMM(dateReceived) {
    let formatDate = new Date(dateReceived);
    let month = formatDate.getMonth() + 1;
    return month
}
export function FormatDateDD(dateReceived) {
    let formatDate = new Date(dateReceived);
    let date = formatDate.getDate();

    return date
}
export function FormatDateYYYY(dateReceived) {
    let formatDate = new Date(dateReceived);
    let year = formatDate.getFullYear();
    return year
}
export function DiffInDate(startDate, timeEnd) {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    let result = moment(newStartDate).diff(newEndDate, 'days')
    return result
  }
export function UndefinedReturnNull(value) {
    if (value === undefined) {
        return null
    } else {
        return value
    }
}
export function UndefinedReturnZero(value) {
    if (value === undefined) {
        return 0
    } else {
        return value
    }
}

export const CustomTooltipGas = ({ active, payload, label }) => {
    //console.log(payload)
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px 10px 10px 10px' }}>
                <span className="label" style={{ color: '#000000' }}>Date: {" "}  {`${payload[0].payload.Date}`}</span><br></br>
                <span className="label" style={{ color: payload[0].color }}>Regular Gallon: {" "}
                    <NumberFormat value={payload[0].payload.RegularGallon} displayType={'text'} thousandSeparator={true} /></span><br></br>
                <span className="label" style={{ color: payload[0].color }}>Regular Sale: {" "}
                    <NumberFormat value={payload[0].payload.RegularTotalSale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[1].color }}>Premium Gallon: {" "}
                    <NumberFormat value={payload[1].payload.PremiumGallon} displayType={'text'} thousandSeparator={true} /></span><br></br>
                <span className="label" style={{ color: payload[1].color }}>Premium Sale: {" "}
                    <NumberFormat value={payload[1].payload.PremiumTotalSale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[2].color }}>Diesel Gallon: {" "}
                    <NumberFormat value={payload[2].payload.DieselGallon} displayType={'text'} thousandSeparator={true} /></span><br></br>
                <span className="label" style={{ color: payload[2].color }}>Diesel Sale: {" "}
                    <NumberFormat value={payload[2].payload.DieselTotalSale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
            </div>

        );
    }
    return null;
};

export const CustomTooltipGrocery = ({ active, payload, label }) => {
    //console.log(payload)
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px 10px 10px 10px' }}>
                <span className="label" style={{ color: '#000000' }}>Date: {" "}  {`${payload[0].payload.Date}`}</span><br></br>
                <span className="label" style={{ color: payload[0].color }}>Tax: {" "}
                    <NumberFormat value={payload[0].payload.Tax} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[1].color }}>NonTax: {" "}
                    <NumberFormat value={payload[1].payload.NonTax} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[2].color }}>Beer: {" "}
                    <NumberFormat value={payload[2].payload.Beer} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[3].color }}>Cigratte: {" "}
                    <NumberFormat value={payload[3].payload.Cigratte} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[4].color }}>Other NonTax: {" "}
                    <NumberFormat value={payload[4].payload.OtherNonTax} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>
                <span className="label" style={{ color: payload[5].color }}>Other Tax: {" "}
                    <NumberFormat value={payload[5].payload.OtherTax} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span><br></br>

            </div>
        );
    }
    return null;
};

export const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        background: "linear-gradient(45deg, #5AB4FB 30%, #289FFC 90%)",
        borderRadius: 3,
        border: 0,
        height: 48,
        padding: "0 10px",
        boxShadow: "0 3px 5px 2px rgba(4, 116, 204, .3)",
    },
    label: {
        color: "#ffffff",
        textTransform: "capitalize",
    },
}));
export function ImportData() {
    const [Data, setData] = React.useState();
    let users = []
    var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const gdbRefPermitHistory = firebase.database().ref(WarrentyHistory + '/' + username1);
    gdbRefPermitHistory.get().then((snapshot) => {

        if (snapshot.exists()) {
            //console.log("data available");
            snapshot.forEach((snap) => {
                users.push({ id: snap.key, ...snap.val() });
            });
            console.log(users)
            setData(users)
            return users
        } else {
            console.log("No data available");
        }
    }).catch((error) => {
        console.log("No data available");
        console.error(error);
    });
    //console.log(Data)
    return Data
}
export function filterUsername() {
    return firebase.auth().currentUser.email.split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
}
export function currentUserUID() {
    const UserUID =  firebase.auth().currentUser;
    console.log(UserUID.email)
    return UserUID.uid
}

