/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useRef } from 'react';
import { Col, Row, Form, Card, CardBody } from 'reactstrap';
// core components
import firebase from 'firebase/app';
import 'firebase/auth';
import { LotteryReport } from '../CustomComponent/BackendDataConfig';
import 'firebase/firestore';
import 'firebase/database';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useStyles } from '../CustomComponent/reuseableFn';

export function AddGameLottery(GameInBox) {
  const [ShowUpdateSuccessMessage, setShowUpdateSuccessMessage] = React.useState(false);
  const [BoxNo, setBoxNo] = React.useState();
  const [GameNo, setGameNo] = React.useState();
  const [GameName, setGameName] = React.useState();
  const [GamePrice, setGamePrice] = React.useState();
  const [PackSize, setPackSize] = React.useState();
  const [GameImage, setGameImage] = React.useState();
  
  const classes = useStyles();
  const inputFile = useRef(null);
  var username =
    localStorage.getItem('email') === null
      ? 'kingswayanish'
      : localStorage
          .getItem('email')
          .split('@')[0]
          .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

  const handleOrderForm = (event) => {
    event.preventDefault();
    //below conditin will CRUD ticket number into the system
    firebase
      .database()
      .ref(LotteryReport + '/' + username + '/GameInBox')
      .push({
        BoxNo: BoxNo,
        GameNo: GameNo,
        GameName: GameName,
        GamePrice: GamePrice,
        PackSize: PackSize,
        GameImage: GameImage,
      });

    setTimeout(function () {
      setShowUpdateSuccessMessage(false);
      setBoxNo('');
      setGameNo('');
      setGameName('');
      setGamePrice('');
      setPackSize('');
      setGameImage('');
    }, 2000); //wait 5 seconds
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setGameImage(base64);
  };
  console.log(BoxNo)
  return (
    <>
      {ShowUpdateSuccessMessage === true ? (
        <>
          <Alert severity="success">Add Game Successfully</Alert>
        </>
      ) : null}
      <Form role="form" onSubmit={handleOrderForm}>
        <Card>
          <CardBody>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Add New Game Form</h3>
              </div>
              <div className="col text-right">
                <Button
                  classes={{
                    root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                  }}
                  type="submit">
                  Add Game
                </Button>
              </div>
            </Row>
            <Row>
              <Col sm={2}>
                <TextField
                  id="standard-helperText"
                  label="Box No"
                  value={BoxNo}
                  variant="standard"
                  onChange={(value) => setBoxNo(value.target.value)}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="standard-helperText"
                  label="Game No"
                  value={GameNo}
                  variant="standard"
                  onChange={(value) => setGameNo(value.target.value)}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="standard-helperText"
                  label="Game Name"
                  value={GameName}
                  variant="standard"
                  onChange={(value) => setGameName(value.target.value)}
                />
              </Col>
              <Col sm={2}>
                <CurrencyTextField
                  label="Game Price"
                  value={GamePrice}
                  variant="standard"
                  currencySymbol="$"
                  onChange={(value) => setGamePrice(value.target.value)}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="standard-helperText"
                  label="Pack Size"
                  value={PackSize}
                  variant="standard"
                  onChange={(value) => setPackSize(value.target.value)}
                />
              </Col>
            </Row>
            <br></br>
            <br></br>
            <Row className="align-items-left">
              <Col sm={2}>
                <Button
                  classes={{
                    root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                  }}
                  color="primary"
                  variant="contained"
                  component="span"
                  type="file"
                  onClick={() => inputFile.current.click()}>
                  Upload Image
                </Button>
                <input
                  ref={inputFile}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/*"
                />
              </Col>
              <Col sm={10}>
                <div>Image Preview</div><br/>
                <img src={GameImage} height="300px" alt='' />
              </Col>
            </Row>
            <br></br>
          </CardBody>
        </Card>
      </Form>
    </>
  );
}
export default AddGameLottery;
