/*!
=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
import { Container } from "reactstrap";
// core components
import firebase from "firebase/app";
import "firebase/auth";
import { PermitsHistory, } from "./CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import {
  tableIcons, FormatDateMMDDYYY, UndefinedReturnNull, DiffInDate,
} from "./CustomComponent/reuseableFn";
import { PermitsHistoryColumn } from "./CustomComponent/TableColumn";
import TableHeaderColor, { aboutToExpirePermit, activePermit, expiredPermit } from "./CustomComponent/Colors";

export function Permits() {
  const [PermitsHistoryData, setPermitsHistoryData] = React.useState([])
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();

  // to filter the data before fetch
  var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const gdbRefPermitHistory = firebase.database().ref(PermitsHistory + '/' + username1);
    gdbRefPermitHistory.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        //console.log(users)
        setPermitsHistoryData(users)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.log("No data available");
      console.error(error);
    });
  }, []); // placing this will render on state change

  //send data to database and create Purchase for customers
  if (PermitsHistoryData[0] !== undefined) {
    if (SelectedRowUpdate !== undefined) {
      firebase.database().ref(PermitsHistory + '/' + username + "/" + SelectedRowUpdate.id).set({
        StartDate: FormatDateMMDDYYY(SelectedRowUpdate.StartDate),
        EndDate: FormatDateMMDDYYY(SelectedRowUpdate.EndDate),
        PermitName: UndefinedReturnNull(SelectedRowUpdate.PermitName),
        PermitNo: UndefinedReturnNull(SelectedRowUpdate.PermitNo),
        Agency: UndefinedReturnNull(SelectedRowUpdate.Agency),
        Note: UndefinedReturnNull(SelectedRowUpdate.Note),
      })
      setSelectedRowUpdate(undefined)
    } else if (SelectedRowAdd !== undefined) {
      firebase.database().ref(PermitsHistory + '/' + username).push({
        StartDate: FormatDateMMDDYYY(SelectedRowAdd.StartDate),
        EndDate: FormatDateMMDDYYY(SelectedRowAdd.EndDate),
        PermitName: UndefinedReturnNull(SelectedRowAdd["PermitName"]),
        PermitNo: UndefinedReturnNull(SelectedRowAdd['PermitNo']),
        Agency: UndefinedReturnNull(SelectedRowAdd['Agency']),
        Note: UndefinedReturnNull(SelectedRowAdd['Note']),
      })
      setSelectedRowAdd(undefined)
    } else if (SelectedRowDelete !== undefined) {
      firebase.database().ref(PermitsHistory + '/' + username + "/" + SelectedRowDelete.id).set(null)
      setSelectedRowDelete(undefined)
    }
  }
  //console.log(PermitsHistoryData)
  // console.log(SelectedRowUpdate)

  return (
    <>
      <br /><br /><br /><br></br>
      <Container className="mt--6" fluid>
        <MaterialTable
          icons={tableIcons}
          title="Active Permit History"
          columns={PermitsHistoryColumn.map((c) => ({ ...c, tableData: undefined }))}
          data={PermitsHistoryData}
          options={{
            exportButton: true,
            rowStyle: rowData => {
              let dateDiff = DiffInDate(new Date(), rowData.EndDate);
              if (dateDiff < 0) {
                if (Math.abs(dateDiff) < 60) {
                  return { backgroundColor: aboutToExpirePermit }
                } else {
                  return { backgroundColor: activePermit}
                }
              } else if (dateDiff > 0) {

                return { backgroundColor: expiredPermit }

              }
            },
            headerStyle: {
              backgroundColor: TableHeaderColor,
              color: "#000000",
              textColor: "#000000",
            },
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve) => {
                setTimeout(() => {
                  setSelectedRowAdd(newData)
                  setPermitsHistoryData([...PermitsHistoryData, newData]);
                  resolve();
                }, 1000)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const dataUpdate = [...PermitsHistoryData];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setPermitsHistoryData([...dataUpdate]);
                  setSelectedRowUpdate(dataUpdate[index])
                  resolve();
                }, 1000)
              }),
            /*onRowDelete: oldData =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const dataDelete = [...PermitsHistoryData];
                  const index = oldData.tableData.id;
                  const dataRemove = dataDelete.splice(index, 1);
                  setPermitsHistoryData([...dataDelete]);
                  setSelectedRowDelete(dataRemove[0])
                  resolve()
                }, 1000)
              }),*/
          }}
        />
      </Container>
      <br></br>
    </>
  );
}
export default Permits;
