/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import { Row, Col, CardHeader, Label, Container } from 'reactstrap';
// core components
import firebase from 'firebase/app';
import 'firebase/auth';
import { LotteryReport } from './CustomComponent/BackendDataConfig';
import 'firebase/firestore';
import 'firebase/database';
import NumberFormat from 'react-number-format';
import MaterialTable from 'material-table';
import { tableIcons, useStyles, FormatDateMMDDYYY } from './CustomComponent/reuseableFn';
import Button from '@material-ui/core/Button';
import UpdateLottery from './SubLottery/UpdateLottery';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import NewLottery from './SubLottery/NewLottery';
import {
  GameHistoryByDay,
  SummaryDailyLottery,
  ActivatedGame,
} from './CustomComponent/TableColumn';
import TableHeaderColor from './CustomComponent/Colors';
import Alert from '@mui/material/Alert';
export function Lottery() {
  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const [LotteryExistingEdit, setLotteryExistingEdit] = React.useState(false);
  const [ViewSummaryLottery, setViewSummaryLottery] = React.useState(true);
  const [openDialogOrderDetails, setOpenDialogOrderDetails] = React.useState(false);
  const [LotteryHistory, setLotteryHistory] = React.useState([]);
  const [GameInBox, setGameInBox] = React.useState([]);
  const [ActivatedPackByDate, setActivatedPackByDate] = React.useState([]);
  const [SelectedRowDataSummary, setSelectedRowDataSummary] = React.useState({});
  //CRUD for game in box
  const [SelectedRowAddGameBox, setSelectedRowAddGameBox] = React.useState();
  const [SelectedRowUpdateGameBox, setSelectedRowUpdateGameBox] = React.useState();
  const [SelectedRowDeleteGameBox, setSelectedRowDeleteGameBox] = React.useState();
  //CRUD for game in box
  const [SelectedRowAddActivatedPack, setSelectedRowAddActivatedPack] = React.useState();
  const [SelectedRowUpdateActivatedPack, setSelectedRowUpdateActivatedPack] = React.useState();
  const [SelectedRowDeleteActivatedPack, setSelectedRowDeleteActivatedPack] = React.useState();
  //Lottery MTD Calculation
  const [MTDLotterySale, setMTDLotterySale] = React.useState(0);
  const [MTDLotteryCash, setMTDLotteryCash] = React.useState(0);
  const [MTDLottoSale, setMTDLottoSale] = React.useState(0);
  const [MTDLottoCash, setMTDLottoCash] = React.useState(0);

  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');
  const classes = useStyles();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState('xl');
  // to filter the data before fetch
  var username =
    localStorage.getItem('email') === null
      ? 'kingswayanish'
      : localStorage
          .getItem('email')
          .split('@')[0]
          .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
  // column to show on invoice table and create row radomly

  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 =
      localStorage.getItem('email') === null
        ? 'kingswayanish'
        : localStorage
            .getItem('email')
            .split('@')[0]
            .replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    const dbRefOrder = firebase
      .database()
      .ref(
        LotteryReport + '/' + username1 + '/TicketSoldSummary/' + SelectedYear + '/' + SelectedMonth
      );
    const gdbRefGameInBox = firebase.database().ref(LotteryReport + '/' + username1 + '/GameInBox');
    const gdbRefActivatedPack = firebase
      .database()
      .ref(LotteryReport + '/' + username1 + '/ActivatedPack/' + SelectedYear);
    // will fetch summary report of lottery
    dbRefOrder
      .get()
      .then((snapshot) => {
        let users = [];
        if (snapshot.exists()) {
          snapshot.forEach((snap) => {
            users.push({ id: snap.key, ...snap.val() });
          });
          //console.log(users)
          setLotteryHistory(users);
          setMTDLotteryCash(users.reduce((a, b) => (a = a + Number(b.ScractOffCash)), 0));
          setMTDLotterySale(users.reduce((a, b) => (a = a + Number(b.ScractOffAmount)), 0));
          setMTDLottoCash(users.reduce((a, b) => (a = a + Number(b.LottoCash)), 0));
          setMTDLottoSale(users.reduce((a, b) => (a = a + Number(b.LottoSale)), 0));
        } else {
          setLotteryHistory(null);
          setMTDLotteryCash(0);
          setMTDLotterySale(0);
          setMTDLottoCash(0);
          setMTDLottoSale(0);
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    //will fetch ticket in box data
    gdbRefGameInBox
      .get()
      .then((snapshot) => {
        let users = [];
        if (snapshot.exists()) {
          //console.log("data available");
          snapshot.forEach((snap) => {
            users.push({ id: snap.key, ...snap.val() });
          });
          //console.log(users)
          setGameInBox(users);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.log('No data available');
        console.error(error);
      });
    //will fetch activated pack information
    gdbRefActivatedPack
      .get()
      .then((snapshot) => {
        let users = [];
        if (snapshot.exists()) {
          //console.log("data available");
          snapshot.forEach((snap) => {
            users.push({ id: snap.key, ...snap.val() });
          });
          //console.log(users)
          setActivatedPackByDate(users);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.log('No data available');
        console.error(error);
      });
  }, [
    SelectedYear,
    SelectedMonth,
    SelectedRowAddGameBox,
    SelectedRowUpdateGameBox,
    SelectedRowDeleteGameBox,
    SelectedRowAddActivatedPack,
    SelectedRowUpdateActivatedPack,
    SelectedRowDeleteActivatedPack,
  ]); // placing this will render on state change

  //below conditin will CRUD ticket number into the system
  if (GameInBox[0] !== undefined) {
    if (SelectedRowUpdateGameBox !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/GameInBox/' + SelectedRowUpdateGameBox.id)
        .set({
          BoxNo: SelectedRowUpdateGameBox.BoxNo,
          GameNo: SelectedRowUpdateGameBox.GameNo,
          GameName: SelectedRowUpdateGameBox.GameName,
          GamePrice: SelectedRowUpdateGameBox.GamePrice,
          PackSize: SelectedRowUpdateGameBox.PackSize,
        });
      setSelectedRowUpdateGameBox(undefined);
    } else if (SelectedRowAddGameBox !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/GameInBox')
        .push({
          BoxNo: SelectedRowAddGameBox['BoxNo'],
          GameNo: SelectedRowAddGameBox['GameNo'],
          GameName: SelectedRowAddGameBox['GameName'],
          GamePrice: SelectedRowAddGameBox['GamePrice'],
          PackSize: SelectedRowAddGameBox['PackSize'],
        });
      setSelectedRowAddGameBox(undefined);
    } else if (SelectedRowDeleteGameBox !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/GameInBox/' + SelectedRowDeleteGameBox.id)
        .set(null);
      setSelectedRowDeleteGameBox(undefined);
    }
  }

  //below conditin will CRUD activatd pack info into the system
  if (ActivatedPackByDate[0] !== undefined) {
    if (SelectedRowUpdateActivatedPack !== undefined) {
      firebase
        .database()
        .ref(
          LotteryReport +
            '/' +
            username +
            '/ActivatedPack/' +
            SelectedYear +
            '/' +
            SelectedRowUpdateActivatedPack.id
        )
        .set({
          GameNo: SelectedRowUpdateActivatedPack.GameNo,
          PackNo: SelectedRowUpdateActivatedPack.PackNo,
          GamePrice: SelectedRowUpdateActivatedPack.GamePrice,
          DateActivated: FormatDateMMDDYYY(SelectedRowUpdateActivatedPack.DateActivated),
        });
      setSelectedRowUpdateActivatedPack(undefined);
    } else if (SelectedRowAddActivatedPack !== undefined) {
      firebase
        .database()
        .ref(LotteryReport + '/' + username + '/ActivatedPack/' + SelectedYear)
        .push({
          GameNo: SelectedRowAddActivatedPack['GameNo'],
          PackNo: SelectedRowAddActivatedPack['PackNo'],
          GamePrice: SelectedRowAddActivatedPack['GamePrice'],
          DateActivated:
            SelectedRowAddActivatedPack['DateActivated'] !== undefined
              ? FormatDateMMDDYYY(SelectedRowAddActivatedPack['DateActivated'])
              : FormatDateMMDDYYY(new Date()),
        });
      setSelectedRowAddActivatedPack(undefined);
    } else if (SelectedRowDeleteActivatedPack !== undefined) {
      console.log(SelectedRowDeleteActivatedPack);
      firebase
        .database()
        .ref(
          LotteryReport +
            '/' +
            username +
            '/ActivatedPack/' +
            SelectedYear +
            '/' +
            SelectedRowDeleteActivatedPack.id
        )
        .set(null);
      setSelectedRowDeleteActivatedPack(undefined);
    }
  }

  const handleEditExistingLottery = () => {
    setViewSummaryLottery(false);
    setLotteryExistingEdit(true);
  };
  const handleSummaryLottery = () => {
    setViewSummaryLottery(true);
    setLotteryExistingEdit(false);
  };

  const handleClose = () => {
    setOpenDialogOrderDetails(false);
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br></br>
      <Container className="mt--6" fluid>
        {showNewOrderForm === true ? (
          <>
            <CardHeader>
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Daily Lottery Details</h3>
                </div>
                <div className="col text-right">
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    onClick={() => {
                      setShowNewOrderForm(false);
                    }}>
                    Go Back
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <br></br>
            <NewLottery GameInBox={GameInBox} />
          </>
        ) : (
          <>
            <CardHeader className="border-0">
              <Row>
                <Col md={2}>
                  <Label style={{}}>
                    <b>Lotto Sale MTD: </b>
                    <NumberFormat
                      value={Number(MTDLottoSale).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </Label>
                  <br></br>
                  <Label style={{}}>
                    <b>Lotto Cash MTD: </b>
                    <NumberFormat
                      value={Number(MTDLottoCash).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </Label>
                </Col>
                <Col md={2}>
                  <Label style={{}}>
                    <b>Lottery Sale MTD: </b>
                    <NumberFormat
                      value={Number(MTDLotterySale).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </Label>
                  <br></br>
                  <Label style={{}}>
                    <b>Lottery Cash MTD: </b>
                    <NumberFormat
                      value={Number(MTDLotteryCash).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </Label>
                </Col>
                <Col md={8}>
                  <div className="col text-right">
                    <Button
                      classes={{
                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                      }}
                      onClick={handleSummaryLottery}>
                      Lottery Summary
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      classes={{
                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                      }}
                      onClick={handleEditExistingLottery}>
                      Update Lottery
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      classes={{
                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                      }}
                      onClick={() => setShowNewOrderForm(true)}>
                      New Lottery
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <br></br>
            {/*below code will display summary lottery table with data */}
            {LotteryHistory !== null ? (
              <>
                {ViewSummaryLottery === true ? (
                  <>
                    <MaterialTable
                      icons={tableIcons}
                      title="Summary Lotterys"
                      columns={SummaryDailyLottery.map((c) => ({ ...c, tableData: undefined }))}
                      data={LotteryHistory}
                      actions={[
                        {
                          icon: MoreVertIcon,
                          tooltip: 'Scratch-Off Details',
                          onClick: (event, rowData) => {
                            setOpenDialogOrderDetails(true);
                            setSelectedRowDataSummary(rowData);
                          },
                        },
                      ]}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: TableHeaderColor,
                          color: '#000000',
                          textColor: '#000000',
                        },
                      }}
                    />
                    <br></br>
                  </>
                ) : null}
                {LotteryExistingEdit === true ? (
                  <>
                    <UpdateLottery />
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Alert severity="info">There is NO Data for Selected Month & Year</Alert>
                <br></br>
              </>
            )}
          </>
        )}
        {/*below code will print the Lottery*/}
        {LotteryHistory !== null ? (
          <>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openDialogOrderDetails}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title">
              <DialogContent>
                <MaterialTable
                  icons={tableIcons}
                  title={
                    'Summary Lotterys Date:' +
                    SelectedRowDataSummary.Date +
                    ', Total Sale:$' +
                    SelectedRowDataSummary.ScractOffAmount
                  }
                  columns={GameHistoryByDay.map((c) => ({ ...c, tableData: undefined }))}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let newDate2 = new Date(SelectedRowDataSummary.Date);
                      let date2 = newDate2.getDate();
                      let month2 = newDate2.getMonth() + 1;
                      let year2 = newDate2.getFullYear();
                      const gdbRefselectedRowDataSummary = firebase
                        .database()
                        .ref(
                          LotteryReport +
                            '/' +
                            username +
                            '/TicketSoldDetail/' +
                            year2 +
                            '/' +
                            month2 +
                            '/' +
                            date2
                        );
                      // will fetch summary report of lottery
                      gdbRefselectedRowDataSummary
                        .get()
                        .then((snapshot) => {
                          let users = [];
                          if (snapshot.exists()) {
                            snapshot.forEach((snap) => {
                              users.push({ id: snap.key, ...snap.val() });
                            });
                            //console.log(users)
                            resolve({
                              data: users,
                            });
                          } else {
                            resolve({
                              data: null,
                            });
                            console.log('No data available');
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    })
                  }
                  options={{
                    exportButton: true,
                    paging: false,
                    headerStyle: {
                      backgroundColor: TableHeaderColor,
                      color: '#000000',
                      textColor: '#000000',
                    },
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  classes={{
                    root: classes.root, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                  }}
                  onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}

        {/*below code will display table with activated pack information*/}
        {showNewOrderForm === false && LotteryExistingEdit === false ? (
          <MaterialTable
            icons={tableIcons}
            title="Activated Pack"
            columns={ActivatedGame.map((c) => ({ ...c, tableData: undefined }))}
            data={ActivatedPackByDate}
            options={{
              exportButton: true,
              headerStyle: {
                backgroundColor: TableHeaderColor,
                color: '#000000',
                textColor: '#000000',
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setSelectedRowAddActivatedPack(newData);
                    setActivatedPackByDate([...ActivatedPackByDate, newData]);
                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...ActivatedPackByDate];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setActivatedPackByDate([...dataUpdate]);
                    setSelectedRowUpdateActivatedPack(dataUpdate[index]);
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...ActivatedPackByDate];
                    const index = oldData.tableData.id;
                    const dataRemove = dataDelete.splice(index, 1);
                    setActivatedPackByDate([...dataDelete]);
                    setSelectedRowDeleteActivatedPack(dataRemove[0]);
                    resolve();
                  }, 1000);
                }),
            }}
          />
        ) : null}
      </Container>
      <br></br>
    </>
  );
}
export default Lottery;
