// gas column
export const DieselColumn = [
  { title: 'Date', field: 'DieselDate', type: 'date' },
  { title: 'Inventory', field: 'DieselInventory', type: 'number' },
  { title: 'Gallon Sold', field: 'DieselGallon', type: 'number' },
  { title: 'Price', field: 'DieselPrice', type: 'currency' },
  { title: 'Total Sale', field: 'DieselTotalSale', type: 'currency', editable: 'never' },
  { title: 'Reading', field: 'DieselReading', type: 'number' },
];
export default DieselColumn;

export const GasDeliveryColumn = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Regular Gallon', field: 'RegularGallon', type: 'number' },
  { title: 'Regular Price', field: 'RegularPrice', type: 'currency' },
  { title: 'Regular Cost', field: 'RegularCost', type: 'currency', editable: 'never' },
  { title: 'Premium Gallon', field: 'PremiumGallon', type: 'number' },
  { title: 'Premium Price', field: 'PremiumPrice', type: 'currency' },
  { title: 'Premium Cost', field: 'PremiumCost', type: 'currency', editable: 'never' },
  { title: 'Diesel Gallon', field: 'DieselGallon', type: 'number' },
  { title: 'Diesel Price', field: 'DieselPrice', type: 'currency' },
  { title: 'Diesel Cost', field: 'DieselCost', type: 'currency', editable: 'never' },
  { title: 'Total Cost', field: 'TotalCost', type: 'currency', editable: 'never' },
];
export const RegularColumn = [
  { title: 'Date', field: 'RegularDate', type: 'date' },
  { title: 'Inventory', field: 'RegularInventory', type: 'number' },
  { title: 'Gallon Sold', field: 'RegularGallon', type: 'number' },
  { title: 'Price', field: 'RegularPrice', type: 'currency' },
  { title: 'Total Sale', field: 'RegularTotalSale', type: 'currency', editable: 'never' },
  { title: 'Reading', field: 'RegularReading', type: 'number' },
];
export const PremiumColumn = [
  { title: 'Date', field: 'PremiumDate', type: 'date' },
  { title: 'Inventory', field: 'PremiumInventory', type: 'number' },
  { title: 'Gallon Sold', field: 'PremiumGallon', type: 'number' },
  { title: 'Price', field: 'PremiumPrice', type: 'currency' },
  { title: 'Total Sale', field: 'PremiumTotalSale', type: 'currency', editable: 'never' },
  { title: 'Reading', field: 'PremiumReading', type: 'number' },
];

// report
export const SummaryDailyReport = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Grocery', field: 'TotalGrocery', type: 'currency' },
  { title: 'Tax Collected', field: 'TotalTaxCollected', type: 'currency' },
  { title: 'Gas', field: 'GasTotal', type: 'currency' },
  /*{ title: "Lottery Sale", field: "LotterySale", type: "currency" },
    { title: "Lottery Cash", field: "LotteryCash", type: "currency" },
    { title: "Lotto Sale", field: "LottoSale", type: "currency" },
    { title: "Lotto Cash", field: "LottoCash", type: "currency" },*/
  { title: 'Lottery & Lotto Sale', field: 'LotteryLottoSale', type: 'currency' },
  { title: 'Lottery & Lotto Cash', field: 'LotteryLottoCash', type: 'currency' },
  { title: 'Money Order', field: 'MoneyOrder', type: 'currency' },
  { title: 'Check Cashing', field: 'CheckCashing', type: 'currency' },
  { title: 'Western Union', field: 'WesternUnion', type: 'currency' },
  { title: 'Total Sale', field: 'TotalSale', type: 'currency' },
  { title: 'Expense / Purchase', field: 'ExpensePurchase', type: 'currency' },
  { title: 'Over / Short', field: 'OverShortShift', type: 'currency' },
];
export const SummaryDailyGasReport = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Regular Gallon', field: 'GasRegularGallon' },
  { title: 'Regular Price', field: 'GasRegularPrice', type: 'currency' },
  { title: 'Regular Sale', field: 'GasRegularSale', type: 'currency' },
  { title: 'Premium Gallon', field: 'GasPremiumGallon' },
  { title: 'Premium Price', field: 'GasPremiumPrice', type: 'currency' },
  { title: 'Premium Sale', field: 'GasPremiumSale', type: 'currency' },
  { title: 'Diesel Gallon', field: 'GasDieselGallon' },
  { title: 'Diesel Price', field: 'GasDieselPrice', type: 'currency' },
  { title: 'Diesel Sale', field: 'GasDieselSale', type: 'currency' },
];
export const SummaryDailyLotteryReport = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Lottery Sale', field: 'LotterySale', type: 'currency' },
  { title: 'Lottery Cash', field: 'LotteryCash', type: 'currency' },
  { title: 'Lottery(Sale - Cash)', field: 'LotteryDiff', type: 'currency' },
  { title: 'Lotto Sale', field: 'LottoSale', type: 'currency' },
  { title: 'Lotto Cash', field: 'LottoCash', type: 'currency' },
  { title: 'Lotto(Sale - Cash)', field: 'LottoDiff', type: 'currency' },
  { title: 'Total Sale', field: 'LotteryLottoSale', type: 'currency' },
];
export const SummaryDailyCashCheckingMoneyOrder = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Check Cashing', field: 'CheckCashing', type: 'currency' },
  { title: 'Money Order', field: 'MoneyOrder', type: 'currency' },
  { title: 'Western Union', field: 'WesternUnion', type: 'currency' },
];
export const SummaryDailyGroceryReport = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Tax', field: 'Tax', type: 'currency' },
  { title: 'Other Tax', field: 'OtherTax', type: 'currency' },
  { title: 'NonTax', field: 'NonTax', type: 'currency' },
  { title: 'Other NonTax', field: 'OtherNonTax', type: 'currency' },
  { title: 'Beer', field: 'Beer', type: 'currency' },
  { title: 'Cigratte', field: 'Cigratte', type: 'currency' },
  { title: 'Deli', field: 'Deli', type: 'currency' },
  { title: 'Other Food', field: 'OtherFood', type: 'currency' },
  { title: 'Tax Collected', field: 'TotalTaxCollected', type: 'currency' },
  { title: 'Total Grocery', field: 'TotalGrocery', type: 'currency' },
];
//lottery
export const SummaryDailyLottery = [
  { title: 'Date', field: 'Date', type: 'date' },
  { title: 'Lotto Sale', field: 'LottoSale', type: 'currency' },
  { title: 'Lotto Cash', field: 'LottoCash', type: 'currency' },
  { title: 'Scract-Off Sale', field: 'ScractOffAmount', type: 'currency' },
  { title: 'Scract-Off Cash', field: 'ScractOffCash', type: 'currency' },
  { title: 'Scract-Off Quantity', field: 'ScractOffQuantity' },
  { title: 'Total Sale', field: 'LotteryLottoSale', type: 'currency' },
  { title: 'Total Cash', field: 'LotteryLottoCash', type: 'currency' },
];
export const GameHistoryByDay = [
  { title: 'Date', field: 'Date', type: 'date', editable: 'never' },
  { title: 'Box No', field: 'BoxNo', type: 'number', editable: 'never' },
  { title: 'Game No', field: 'GameNo', type: 'number', editable: 'never' },
  { title: 'Game Price', field: 'GamePrice', type: 'currency', editable: 'never' },
  { title: 'Start', field: 'StartOn', type: 'number', editable: 'never' },
  { title: 'End', field: 'EndOn', type: 'number', editable: 'never' },
  { title: 'Quantity', field: 'QuantitySold', type: 'number', editable: 'never' },
  { title: 'Total Amount', field: 'TotalAmount', type: 'currency', editable: 'never' },
];

export const CurrentGameInBox = [
  { title: 'Box No', field: 'BoxNo', type: 'number' },
  { title: 'Game No', field: 'GameNo', type: 'number' },
  { title: 'Game Name', field: 'GameName' },
  { title: 'Game Price', field: 'GamePrice', type: 'currency' },
  { title: 'Pack Size', field: 'PackSize', type: 'number' },
  {
    title: 'Game Image',
    field: 'GameImage',
    type: 'image',
    editable: 'never',
    render: (rowData) => <img style={{ height: 50 }} src={rowData.GameImage} alt=""/>,
  },
];

export const ActivatedGame = [
  { title: 'Game No', field: 'GameNo', type: 'number' },
  { title: 'Pack No', field: 'PackNo', type: 'number' },
  { title: 'Game Price', field: 'GamePrice', type: 'currency' },
  { title: 'Date', field: 'DateActivated', type: 'date' },
];

//expense
export const SummaryDailyExpense = [
  { title: 'Purchase Date', field: 'Date', type: 'date' },
  { title: 'Company Name', field: 'Name' },
  { title: 'Amount', field: 'Amount', type: 'currency' },
  { title: 'Payment Type', field: 'PaymentType' },
  { title: 'Note', field: 'Note' },
];

//permit
export const PermitsHistoryColumn = [
  { title: 'Start Date', field: 'StartDate', type: 'date' },
  { title: 'End Date', field: 'EndDate', type: 'date' },
  { title: 'Permit Name', field: 'PermitName' },
  { title: 'Permit No', field: 'PermitNo' },
  { title: 'Agency', field: 'Agency' },
  { title: 'Note', field: 'Note' },
];

//warrenty
export const WarrentyHistoryColumn = [
  { title: 'Product Name', field: 'ProductName' },
  { title: 'Manufacture', field: 'Manufacture' },
  { title: 'Installation Company', field: 'InstalltionCompany' },
  { title: 'Contact Information', field: 'ContactInformation' },
  { title: 'Warrenty Start', field: 'WarrentyStart', type: 'date' },
  { title: 'Warrenty End', field: 'WarrentyEnd', type: 'date' },
  { title: 'Cost', field: 'Cost', type: 'currency' },
  { title: 'Note', field: 'Note' },
];
