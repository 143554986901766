/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Container,
  Card
} from "reactstrap";
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { GasReport } from "./CustomComponent/BackendDataConfig";
import { tableIcons, FormatDateMMDDYYY, FormatDateYYYY, FormatDateMM, FormatDateDD, CustomTooltipGas, UndefinedReturnZero } from "./CustomComponent/reuseableFn";
import RegualrGasAddUpdate, { DieselGasAddUpdate, PremiumGasAddUpdate } from "./CustomComponent/UpdateAddFirebaseData";
import { DieselColumn, RegularColumn, PremiumColumn, GasDeliveryColumn } from "./CustomComponent/TableColumn";
import TableHeaderColor from "./CustomComponent/Colors";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export function Gas() {
  const [GasDelivery, setGasDelivery] = React.useState([])
  //const [GasInTank, setGasInTank] = React.useState([])
  const [GasRegular, setGasRegular] = React.useState([])
  const [GasPremium, setGasPremium] = React.useState([])
  const [GasDiesel, setGasDiesel] = React.useState([])
  const [ViewType, setViewType] = React.useState('1');
  //CRUD for game in box
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();
  //CRUD for game in box
  const [SelectedRowAddRegular, setSelectedRowAddRegular] = React.useState();
  const [SelectedRowUpdateRegular, setSelectedRowUpdateRegular] = React.useState();
  const [SelectedRowDeleteRegular, setSelectedRowDeleteRegular] = React.useState();
  //CRUD for game in box
  const [SelectedRowAddPremium, setSelectedRowAddPremium] = React.useState();
  const [SelectedRowUpdatePremium, setSelectedRowUpdatePremium] = React.useState();
  const [SelectedRowDeletePremium, setSelectedRowDeletePremium] = React.useState();
  //CRUD for game in box
  const [SelectedRowAddDiesel, setSelectedRowAddDiesel] = React.useState();
  const [SelectedRowUpdateDiesel, setSelectedRowUpdateDiesel] = React.useState();
  const [SelectedRowDeleteDiesel, setSelectedRowDeleteDiesel] = React.useState();
  //Gas MTD Calculation
  const [MTDRegularGallon, setMTDRegularGallon] = React.useState(0);
  const [MTDRegularSale, setMTDRegularSale] = React.useState(0);
  const [MTDPremiumGallon, setMTDPremiumGallon] = React.useState(0);
  const [MTDPremiumSale, setMTDPremiumSale] = React.useState(0);
  const [MTDDieselGallon, setMTDDieselGallon] = React.useState(0);
  const [MTDDieselSale, setMTDDieselSale] = React.useState(0);

  var SelectedMonth = localStorage.getItem('Month');
  var SelectedYear = localStorage.getItem('Year');

  // to filter the data before fetch
  var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

  //initialize to get data of all customers, order history
  useEffect(() => {
    var username1 = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    //will fetch gas delivery data
    const gdbRefGasDelivery = firebase.database().ref(GasReport + '/' + username1 + "/GasDelivery/" + SelectedYear);
    gdbRefGasDelivery.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        //console.log(users)
        setGasDelivery(users)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.log("No data available");
      console.error(error);
    });
    //gas gallons in tank ** not working
    /* const gdbRefGasInTank = firebase.database().ref(GasReport + '/' + username1 + "/GasInTank");
     gdbRefGasInTank.get().then((snapshot) => {
       let users = [];
       if (snapshot.exists()) {
         //console.log("data available");
         snapshot.forEach((snap) => {
           users.push(snap.val());
         });
         console.log(users)
         setGasInTank(users)
       } else {
         setGasInTank({
           Date: 0,
           RegularInTankGallon: 0,
           RegularPrice: 0,
           RegularCost: 0,
           PremiumInTankGallon: 0,
           PremiumPrice: 0,
           PremiumCost: 0,
           DieselInTankGallon: 0,
           DieselPrice: 0,
           DieselCost: 0,
         })
         console.log("No data available");
       }
     }).catch((error) => {
       console.log("No data available");
       console.error(error);
     });*/
    //will fetch regular gas data
    const gdbRefGasRegular = firebase.database().ref(GasReport + '/' + username1 + "/GasDailyReport/Regular/" + SelectedYear + "/" + SelectedMonth);
    gdbRefGasRegular.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        //console.log(users)
        setGasRegular(users)
        setMTDRegularGallon(users.reduce((a, b) => a = a + Number(b.RegularGallon), 0))
        setMTDRegularSale(users.reduce((a, b) => a = a + Number(b.RegularTotalSale), 0))
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.log("No data available");
      console.error(error);
    });
    //will fetch Premium gas data
    const gdbRefGasPremium = firebase.database().ref(GasReport + '/' + username1 + "/GasDailyReport/Premium/" + SelectedYear + "/" + SelectedMonth);
    gdbRefGasPremium.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        //console.log(users)
        setGasPremium(users)
        setMTDPremiumGallon(users.reduce((a, b) => a = a + Number(b.PremiumGallon), 0))
        setMTDPremiumSale(users.reduce((a, b) => a = a + Number(b.PremiumTotalSale), 0))
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.log("No data available");
      console.error(error);
    });
    //will fetch diesel gas data
    const gdbRefGasDiesel = firebase.database().ref(GasReport + '/' + username1 + "/GasDailyReport/Diesel/" + SelectedYear + "/" + SelectedMonth);
    gdbRefGasDiesel.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        //console.log("data available");
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        //console.log(users)
        setGasDiesel(users)
        setMTDDieselGallon(users.reduce((a, b) => a = a + Number(b.DieselGallon), 0))
        setMTDDieselSale(users.reduce((a, b) => a = a + Number(b.DieselTotalSale), 0))
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.log("No data available");
      console.error(error);
    });

  }, [SelectedYear, SelectedMonth, SelectedRowAdd, SelectedRowUpdate, SelectedRowDelete]); // placing this will render on state change

  // below condition to format data for line chart
  var data = [];
  if (GasRegular[0] !== undefined && GasPremium[0] !== undefined && GasDiesel[0] !== undefined) {
    //console.log(GasRegular)
    GasRegular.map((a, index) => {
      data.push({
        Date: a.RegularDate,
        RegularGallon: a.RegularGallon,
        RegularTotalSale: a.RegularTotalSale,
        RegularPrice: a.RegularPrice,
        PremiumGallon: GasPremium[index] !== undefined ? GasPremium[index].PremiumGallon : 0,
        PremiumTotalSale: GasPremium[index] !== undefined ? GasPremium[index].PremiumTotalSale : 0,
        PremiumPrice: GasPremium[index] !== undefined ? GasPremium[index].PremiumPrice : 0,
        DieselGallon: GasDiesel[index] !== undefined ? GasDiesel[index].DieselGallon : 0,
        DieselTotalSale: GasDiesel[index] !== undefined ? GasDiesel[index].DieselTotalSale : 0,
        DieselPrice: GasDiesel[index] !== undefined ? GasDiesel[index].DieselPrice : 0
      })
      return null;
    })
  }
  //console.log(GasPremium[count].PremiumGallon)
  //console.log(GasInTank)
  //below condition will CRUD Gas Delivery
  if (SelectedRowUpdate !== undefined) {
    firebase.database().ref(GasReport + '/' + username + "/GasDelivery/" + SelectedYear + "/" + SelectedRowUpdate.id).set({
      Date: FormatDateMMDDYYY(SelectedRowUpdate.Date),
      RegularGallon: Number(UndefinedReturnZero(SelectedRowUpdate.RegularGallon)),
      RegularPrice: Number(UndefinedReturnZero(SelectedRowUpdate.RegularPrice)),
      RegularCost: Number(UndefinedReturnZero(SelectedRowUpdate.RegularGallon)) * Number(UndefinedReturnZero(SelectedRowUpdate.RegularPrice)),
      PremiumGallon: Number(UndefinedReturnZero(SelectedRowUpdate.PremiumGallon)),
      PremiumPrice: Number(UndefinedReturnZero(SelectedRowUpdate.PremiumPrice)),
      PremiumCost: Number(UndefinedReturnZero(SelectedRowUpdate.PremiumPrice)) * Number(UndefinedReturnZero(SelectedRowUpdate.PremiumGallon)),
      DieselGallon: Number(UndefinedReturnZero(SelectedRowUpdate.DieselGallon)),
      DieselPrice: Number(UndefinedReturnZero(SelectedRowUpdate.DieselPrice)),
      DieselCost: Number(UndefinedReturnZero(SelectedRowUpdate.DieselGallon)) * Number(UndefinedReturnZero(SelectedRowUpdate.DieselPrice)),
      TotalCost: (Number(UndefinedReturnZero(SelectedRowUpdate.RegularGallon)) * Number(UndefinedReturnZero(SelectedRowUpdate.RegularPrice))) +
        (Number(UndefinedReturnZero(SelectedRowUpdate.PremiumPrice)) * Number(UndefinedReturnZero(SelectedRowUpdate.PremiumGallon))) +
        (Number(UndefinedReturnZero(SelectedRowUpdate.DieselGallon)) * Number(UndefinedReturnZero(SelectedRowUpdate.DieselPrice)))
    })
    setSelectedRowUpdate(undefined)
  } else if (SelectedRowAdd !== undefined) {
    firebase.database().ref(GasReport + '/' + username + "/GasDelivery/" + SelectedYear).push({
      Date: FormatDateMMDDYYY(SelectedRowAdd.Date),
      RegularGallon: Number(UndefinedReturnZero(SelectedRowAdd['RegularGallon'])),
      RegularPrice: Number(UndefinedReturnZero(SelectedRowAdd['RegularPrice'])),
      RegularCost: Number(UndefinedReturnZero(SelectedRowAdd.RegularGallon)) * Number(UndefinedReturnZero(SelectedRowAdd.RegularPrice)),
      PremiumGallon: Number(UndefinedReturnZero(SelectedRowAdd['PremiumGallon'])),
      PremiumPrice: Number(UndefinedReturnZero(SelectedRowAdd['PremiumPrice'])),
      PremiumCost: Number(UndefinedReturnZero(SelectedRowAdd.PremiumGallon)) * Number(UndefinedReturnZero(SelectedRowAdd.PremiumPrice)),
      DieselGallon: Number(UndefinedReturnZero(SelectedRowAdd['DieselGallon'])),
      DieselPrice: Number(UndefinedReturnZero(SelectedRowAdd['DieselPrice'])),
      DieselCost: Number(UndefinedReturnZero(SelectedRowAdd.DieselGallon)) * Number(UndefinedReturnZero(SelectedRowAdd.DieselPrice)),
      TotalCost: (Number(UndefinedReturnZero(SelectedRowAdd.RegularGallon)) * Number(UndefinedReturnZero(SelectedRowAdd.RegularPrice))) +
        (Number(UndefinedReturnZero(SelectedRowAdd.PremiumGallon)) * Number(UndefinedReturnZero(SelectedRowAdd.PremiumPrice))) +
        (Number(UndefinedReturnZero(SelectedRowAdd.DieselGallon)) * Number(UndefinedReturnZero(SelectedRowAdd.DieselPrice)))
    })
    setSelectedRowAdd(undefined)
  } else if (SelectedRowDelete !== undefined) {
    firebase.database().ref(GasReport + '/' + username + "/GasDelivery/" + SelectedYear + "/" + SelectedRowDelete.id).set(null)

    setSelectedRowDelete(undefined)
  }

  //CRUD Gas Regular
  if (SelectedRowUpdateRegular !== undefined) {
    RegualrGasAddUpdate(SelectedRowUpdateRegular, "GasDailyReport/Regular")
    setSelectedRowUpdateRegular(undefined)
  } else if (SelectedRowAddRegular !== undefined) {
    RegualrGasAddUpdate(SelectedRowAddRegular, "GasDailyReport/Regular")
    setSelectedRowAddRegular(undefined)
  } else if (SelectedRowDeleteRegular !== undefined) {
    firebase.database().ref(GasReport + '/' + username + "/GasDailyReport/Regular/" + FormatDateYYYY(SelectedRowDeleteRegular.RegularDate)
      + "/" + FormatDateMM(SelectedRowDeleteRegular.RegularDate) + "/" + FormatDateDD(SelectedRowDeleteRegular.RegularDate)).set(null)
    setSelectedRowDeleteRegular(undefined)
  }

  //CRUD Gas premium
  if (SelectedRowUpdatePremium !== undefined) {
    PremiumGasAddUpdate(SelectedRowUpdatePremium, "GasDailyReport/Premium")
    setSelectedRowUpdatePremium(undefined)
  } else if (SelectedRowAddPremium !== undefined) {
    PremiumGasAddUpdate(SelectedRowAddPremium, "GasDailyReport/Premium")
    setSelectedRowAddPremium(undefined)
  } else if (SelectedRowDeletePremium !== undefined) {
    firebase.database().ref(GasReport + '/' + username + "/GasDailyReport/Premium/" + FormatDateYYYY(SelectedRowDeletePremium.PremiumDate) + "/"
      + FormatDateMM(SelectedRowDeletePremium.PremiumDate) + "/" + FormatDateDD(SelectedRowDeletePremium.PremiumDate)).set(null)
    setSelectedRowDeletePremium(undefined)
  }

  //CRUD Gas Diesel
  if (SelectedRowUpdateDiesel !== undefined) {
    DieselGasAddUpdate(SelectedRowUpdateDiesel, "GasDailyReport/Diesel")
    setSelectedRowUpdateDiesel(undefined)
  } else if (SelectedRowAddDiesel !== undefined) {
    DieselGasAddUpdate(SelectedRowAddDiesel, "GasDailyReport/Diesel")
    setSelectedRowAddDiesel(undefined)
  } else if (SelectedRowDeleteDiesel !== undefined) {
    firebase.database().ref(GasReport + '/' + username + "/GasDailyReport/Diesel/" + FormatDateYYYY(SelectedRowDeleteDiesel.DieselDate) + "/"
      + FormatDateMM(SelectedRowDeleteDiesel.DieselDate) + "/" + FormatDateDD(SelectedRowDeleteDiesel.DieselDate)).set(null)
    setSelectedRowDeleteDiesel(undefined)
  }
  //console.log('add')
  //console.log(SelectedRowAdd)
  //console.log('update')
  //console.log(SelectedRowUpdate)
  //console.log('delete')
  //console.log(SelectedRowDelete)
  //console.log(GasDelivery)
  //console.log(GasHistory)
  // const CustomTooltip = ({ active, payload, label }) => {
  //   console.log(payload)
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip">
  //         <p className="label">{`${label} : ${payload.RegularGallon}`}</p>
  //         <p className="intro">{ }</p>
  //         <p className="desc">Anything you want can be displayed here.</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };
  return (
    <>
      <br /><br /><br /><br></br>

      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Regular in Tank: <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
              </CardHeader>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Premium in Tank: <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
              </CardHeader>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Diesel in Tank: <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="4">
            <Card>
              <CardBody className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Regular Gallon MTD: <NumberFormat value={MTDRegularGallon} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Regular Sale MTD:<NumberFormat value={MTDRegularSale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardBody className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Premium Gallon MTD:<NumberFormat value={MTDPremiumGallon} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Premium Sale MTD:<NumberFormat value={MTDPremiumSale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardBody className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Diesel Gallon MTD: <NumberFormat value={MTDDieselGallon} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Diesel Sale MTD: <NumberFormat value={MTDDieselSale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/*below code will display summary Gas table with data */}
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <FormControl>
                  <RadioGroup
                    row
                    value={ViewType}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(event, value) => setViewType(value)}
                  >
                    <FormLabel id="demo-row-radio-buttons-group-label">Graph View:</FormLabel>&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControlLabel value={'1'} control={<Radio />} label="Bar" />
                    <FormControlLabel value={'0'} control={<Radio />} label="Line " />
                  </RadioGroup>
                </FormControl>
                {ViewType === '0' ?
                  <>
                    <ResponsiveContainer width="100%" height={420}>
                      <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" />
                        <YAxis />
                        <Tooltip content={<CustomTooltipGas />} />
                        <Legend />
                        <Line type="monotone" dataKey="RegularGallon" stroke="#F60000" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="PremiumGallon" stroke="#001EF6" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="DieselGallon" stroke="#00AF1A" activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </> : ViewType === '1' ?
                    <>
                      <ResponsiveContainer width="100%" height={420}>
                        <BarChart data={data}                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="Date" />
                          <YAxis />
                          <Tooltip content={<CustomTooltipGas />} />
                          <Legend />
                          <Bar dataKey="RegularGallon" stackId="a" fill="#ff6161" barSize={20} />
                          <Bar dataKey="PremiumGallon" stackId="a" fill="#576bff" barSize={20} />
                          <Bar dataKey="DieselGallon" stackId="a" fill="#4dab5b" barSize={20} />
                        </BarChart>
                      </ResponsiveContainer>
                    </> : null}
              </CardBody>
            </Card>
            <br></br>
          </Col>
          <Col md={6}>
            {/*regular gas */}
            <MaterialTable
              icons={tableIcons}
              title="Regular"
              columns={RegularColumn.map((c) => ({ ...c, tableData: undefined }))}
              data={GasRegular}
              options={{
                exportButton: true,
                headerStyle: {
                  backgroundColor: TableHeaderColor,
                  color: "#000000",
                  textColor: "#000000",
                },
              }}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      setSelectedRowAddRegular(newData)
                      setGasRegular([...GasRegular, newData]);
                      resolve();
                    }, 1000)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataUpdate = [...GasRegular];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setGasRegular([...dataUpdate]);
                      setSelectedRowUpdateRegular(dataUpdate[index])
                      resolve();
                    }, 1000)
                  }),
                /*onRowDelete: oldData =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataDelete = [...GasRegular];
                      const index = oldData.tableData.id;
                      const dataRemove = dataDelete.splice(index, 1);
                      setGasRegular([...dataDelete]);
                      setSelectedRowDeleteRegular(dataRemove[0])
                      resolve()
                    }, 1000)
                  }),*/
              }}
            />
            <br></br>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {/*Premium gas */}
            <MaterialTable
              icons={tableIcons}
              title="Premium"
              columns={PremiumColumn.map((c) => ({ ...c, tableData: undefined }))}
              data={GasPremium}
              options={{
                exportButton: true,
                headerStyle: {
                  backgroundColor: TableHeaderColor,
                  color: "#000000",
                  textColor: "#000000",
                },
              }}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      setSelectedRowAddPremium(newData)
                      setGasPremium([...GasPremium, newData]);
                      resolve();
                    }, 1000)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataUpdate = [...GasPremium];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setGasPremium([...dataUpdate]);
                      setSelectedRowUpdatePremium(dataUpdate[index])
                      resolve();
                    }, 1000)
                  }),
                /*onRowDelete: oldData =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataDelete = [...GasPremium];
                      const index = oldData.tableData.id;
                      const dataRemove = dataDelete.splice(index, 1);
                      setGasPremium([...dataDelete]);
                      setSelectedRowDeletePremium(dataRemove[0])
                      resolve()
                    }, 1000)
                  }),*/
              }}
            />
            <br></br>
          </Col>
          <Col md={6}>
            {/*diesel gas */}
            <MaterialTable
              icons={tableIcons}
              title="Diesel"
              columns={DieselColumn.map((c) => ({ ...c, tableData: undefined }))}
              data={GasDiesel}
              options={{
                exportButton: true,
                headerStyle: {
                  backgroundColor: TableHeaderColor,
                  color: "#000000",
                  textColor: "#000000",
                },
              }}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      setSelectedRowAddDiesel(newData)
                      setGasDiesel([...GasDiesel, newData]);
                      resolve();
                    }, 1000)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataUpdate = [...GasDiesel];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setGasDiesel([...dataUpdate]);
                      setSelectedRowUpdateDiesel(dataUpdate[index])
                      resolve();
                    }, 1000)
                  }),
                /*onRowDelete: oldData =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataDelete = [...GasDiesel];
                      const index = oldData.tableData.id;
                      const dataRemove = dataDelete.splice(index, 1);
                      setGasDiesel([...dataDelete]);
                      setSelectedRowDeleteDiesel(dataRemove[0])
                      resolve()
                    }, 1000)
                  }),*/
              }}
            />
            <br></br>
          </Col>
        </Row>
        {/*below code will display table with Gas box information*/}
        <MaterialTable
          icons={tableIcons}
          title="Gas Delivery"
          columns={GasDeliveryColumn.map((c) => ({ ...c, tableData: undefined }))}
          data={GasDelivery}
          options={{
            exportButton: true,
            headerStyle: {
              backgroundColor: TableHeaderColor,
              color: "#000000",
              textColor: "#000000",
            },
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve) => {
                setTimeout(() => {
                  setSelectedRowAdd(newData)
                  setGasDelivery([...GasDelivery, newData]);
                  resolve();
                }, 1000)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const dataUpdate = [...GasDelivery];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setGasDelivery([...dataUpdate]);
                  setSelectedRowUpdate(dataUpdate[index])
                  resolve();
                }, 1000)
              }),
            /*onRowDelete: oldData =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const dataDelete = [...GasDelivery];
                  const index = oldData.tableData.id;
                  const dataRemove = dataDelete.splice(index, 1);
                  setGasDelivery([...dataDelete]);
                  setSelectedRowDelete(dataRemove[0])
                  resolve()
                }, 1000)
              }),*/
          }}
        />
      </Container>
      <br></br>
    </>
  );
}
export default Gas;
