/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Expense from "views/adminUI/Expense";
import Dashboard from "views/adminUI/Dashboard.js";
//import Customers from "views/adminUI/Customers";
//import SupportTicket from "views/adminUI/SupportTicket";
import Report from "views/adminUI/Report";
import Lottery from "views/adminUI/Lottery";
import Gas from "views/adminUI/Gas";
import Purchase from "views/adminUI/Purchase";
import Permits from "views/adminUI/Permits";
import Warrenty from "views/adminUI/Warrenty";
import Profile from "views/adminUI/Profile";
import Subscription from "views/adminUI/Subscription";
//import Support from "views/adminUI/Support";
import Settings from "views/adminUI/Settings";
import Payroll from "views/adminUI/Payroll";
import LotteryDisplay from "views/adminUI/LotteryDisplay";

export const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-area",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Daily Report",
    icon: "fa fa-book",
    component: Report,
    layout: "/admin",
  },
  {
    path: "/lottery",
    name: "Lottery Sale",
    icon: "fa fa-gamepad",
    component: Lottery,
    layout: "/admin",
  },
  {
    path: "/lottery-display",
    name: "Lottery Display",
    icon: "fas fa-tv",
    component: LotteryDisplay,
    layout: "/admin",
  },
  {
    path: "/gas",
    name: "Gas Sale",
    icon: "fas fa-gas-pump",
    component: Gas,
    layout: "/admin",
  },
  {
    path: "/purchase",
    name: "Purchase Inventory",
    icon: "fas fa-money-bill-alt",
    component: Purchase,
    layout: "/admin",
  },
  {
    path: "/expense",
    name: "Daily Expense",
    icon: "fa fa-credit-card",
    component: Expense,
    layout: "/admin",
  },
  {
    path: "/permits",
    name: "Permits",
    icon: "fas fa-certificate",
    component: Permits,
    layout: "/admin",
  },
  {
    path: "/warrenty",
    name: "Warrenty Records",
    icon: "fas fa-stamp",
    component: Warrenty,
    layout: "/admin",
  },
  {
    path: "/payroll",
    name: "Payroll",
    icon: "fas fa-money-bill-alt",
    component: Payroll,
    layout: "/admin",
  },
  /*{
    path: "/customers",
    name: "Customers",
    miniName: "A",
    icon: "fa fa-users",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/supportticket",
    name: "Support Ticket",
    miniName: "A",
    icon: "fa fa-search",
    component: SupportTicket,
    layout: "/admin",
  }*/
];
export default routes;

// this route is for profile, aggrement, setting
export const otherRoutes = [
  {
    path: "/profile",
    name: "Admin Profile",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "Support",
    component: Subscription,
    layout: "/admin",
  },
]
